import { HttpClient } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { Router } from "@angular/router";
import { App, AppInfo } from "@capacitor/app";
import { Device, DeviceInfo } from "@capacitor/device";
//import { Network } from "@awesome-cordova-plugins/network/ngx";
import { AlertController, NavController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import firebase from "firebase/compat/app";
import 'firebase/compat/storage';
import * as moment from "moment";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { Photo } from "../models/photo";
import { User } from "../models/user";
import { Profile } from "./../models/profile";
import { AuthService } from "./auth.service";
import { CommonService } from "./common.service";
import { GeolocationService } from "./geolocation.service";
import { StorageService } from "./storage.service";
import { ToastService } from "./toast.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  public user: User = null;
  public profile: Profile = null;
  private _verified_state_changed = false;
  private _profile_ready = false;
  public isProfileReady = new BehaviorSubject(false);
  public isUserReady = new BehaviorSubject(false);
  public user_disabled: boolean = false;
  public user_has_photos = false;

  private fb_user_ref = null;
  private fb_profile_ref = null;
  private fb_events: Observable<any>;

  private fb_events_sub = null;
  private fb_favorites: Observable<any>;
  private fb_favorites_sub = null;
  private fb_credit_ref = null;
  private fb_photos_requests_ref = null;
  private fb_premium_ref = null;
  private fb_events_ref = null;
  public after_signin = null;
  private _signin_logged = null;

  public nbEvents: object = { total: 0, message: 0, favorite: 0 };
  public favorites = {};
  public photos_requests = {};
  public hasPhotosRequestsChanged = new BehaviorSubject(false);
  private sound = null;
  public redirectUrl = null;
  public nb_private_photos = 0;
  public auth_error = null;
  public deviceInfos = null;
  public ipInfos = null;
  public version = null;
  public platform = null;
  public networkUp = true;

  public coupon = "AX3XSEFG";
  private _offset_time = null;
  private _is_premium = true;
  public credits = { premium: 0, payed: 0 };
  public hide_alerts = {
    dashboard_miles: false,
    dashboard_verified: false,
    profilephotorules: false,
    dashboard_addtrip:false
  };
  private _sent_events = { visit: {} };

  constructor(
    public http: HttpClient,
    public auth: AuthService,
    private db: AngularFireDatabase,
    private router: Router,
    //private network: Network,
    public toast: ToastService,
    public translate: TranslateService,
    public geolocation: GeolocationService,
    public common: CommonService,
    private nav: NavController,
    private alert: AlertController,
    private zone: NgZone,
    private storage: StorageService
  ) {
    if (!this.deviceInfos) {
      this.getDeviceInfos(false);
    }
    if (!this.ipInfos) {
      this.getIpInfos();
    }

    this.auth.isAuthReady.subscribe((authReady) => {
      if (authReady) {
        // user logged in

        this.signIn(this.auth.getAuthUser())
          .then((result) => {
            if (this.after_signin) {
              this.router.navigate([this.after_signin]);
              this.after_signin = null;
            } else {
              this.router.navigate(["/"]);
            }
          })
          .catch((e) => {
            this.auth.logout(false);
          });
      } else {
        // user logged out
        //this.router.navigate['/'];
      }
    });

    // loading google api
    let api_key = environment.gmaps.apiKey;
    this.common.loadExternalScript(`https://maps.googleapis.com/maps/api/js?libraries=places&language=en&key=${api_key}`);
  }

  /*
   * sign in the user
   */
  signIn(authUser) {
    this.unsubscribe();
    return this.loadUser(authUser).then((user: User) => {
      this.loadAlertStatus();
      this.isUserReady.next(true);

      if (!user.displayName || user.displayName == "") {
        Promise.resolve(user);
        this.router.navigate(["/register"]);
      }

      return this.loadProfile().then((profile) => {
        this.loadFavorites(authUser.uid);

         this.checkAppVersion();
      });
      //    return self.loadProfile().then(function(){
      //      self.redirectUser();
      //    });
    });
  }

  signOut(redirect: boolean = false) {
    const self = this;

    return new Promise((resolve, reject) => {
      self.track("sign_out", "Auth");

      self.unsubscribe();

      // remove user and profile
      self.user = new User();
      self.profile = new Profile();
      self.isUserReady = new BehaviorSubject(false);

      resolve(true);
    });
  }

  unsubscribe() {
    // unsubscribe
    if (this.fb_user_ref) {
      this.fb_user_ref.off();
    }
    if (this.fb_profile_ref) {
      this.fb_profile_ref.off();
    }
    if (this.fb_events_sub) {
      this.fb_events_sub.unsubscribe();
    }
    if (this.fb_favorites_sub) {
      this.fb_favorites_sub.unsubscribe();
    }
    if (this.fb_photos_requests_ref) {
      this.fb_photos_requests_ref.off();
    }
    if (this.fb_premium_ref) {
      this.fb_premium_ref.off();
    }
    if (this.fb_credit_ref) {
      this.fb_credit_ref.off();
    }
    if (this.fb_events_ref) {
      this.fb_events_ref.off();
    }
  }

  track(action, category, custom = null) {
    try {
      // do not track admins
      if (this.user.perms.indexOf("admin") > 0) {
        return;
      }
    } catch (e) {}
  }

  loadUser(authUser) {
    this.getUserSkew();

    return new Promise((resolve, reject) => {
      this.fb_user_ref = firebase.database().ref(`/users/${authUser.uid}`);
      this.fb_user_ref.on("value", (snap) => {
        if (snap.exists()) {
          let current_user = Object.assign(new User(), snap.val());
          if (current_user.is_hard_banned) {
            this.auth.setError("You have been banned by the administrators !");
            this.signOut(true).then(() => {
              window.location.href = "/";
            });
            reject("USER_BANNED");
            return;
          }

          if (current_user.is_disabled) {
            current_user.is_disabled = false;
            firebase.database().ref(`/users/${current_user.id}`).update({ is_disabled: false });
          }

          this.user = current_user;

          this.setUserInfos(this.user.id);

          this._verified_state_changed = current_user.emailVerified;

          if (authUser && !this._verified_state_changed && this.user.emailVerified != authUser.emailVerified) {
            let updates = {};
            this._verified_state_changed = true; // only one time
            updates[`/users/${current_user.id}/emailVerified`] = authUser.emailVerified;
            firebase.database().ref().update(updates);
          }
          this.checkPremium();

          this.checkCredits();

          this.countEvents(authUser.uid);
          this.loadPhotosRequests(authUser.uid);

          resolve(current_user);
        } else {
          // user doesn't exist
          if (!this.user) {
            reject("USER_NOT_EXIST");
          } else {
            // current user is set but disapeared from db (user deleted)
            this.router.navigate(["/login"]);
          }
        }
      });
    });
  }

  loadProfile(): Promise<Profile> {
    return new Promise((resolve, reject) => {
      const uid = this.user.id;
      if (this.profile && this.profile.exists()) {
        resolve(this.profile);
        return;
      }
      this.fb_profile_ref = firebase.database().ref(`/members/${uid}`);
      this.fb_profile_ref.on("value", (snap) => {
        if (snap.exists()) {
          this.profile = new Profile(snap.val());
          this.isProfileReady.next(true);
          //        self.hasPrivatePhotos();

          let online = firebase.database().ref(`/members/${uid}/online`);
          online.onDisconnect().set(false);
          this.zone.run(() => {});
          resolve(this.profile);
        } else {
          this.profile = new Profile();
          reject(null);
        }
      });
    });
  }

  updateProfile(updates) {
    firebase.database().ref(`/members/${this.profile.id}`).update(updates);
  }

  updateUser(updates) {
    firebase.database().ref(`/users/${this.user.id}`).update(updates);
  }

  getDeviceInfos(full = true) {
    
      return Device.getInfo().then((infos: DeviceInfo) => {
        this.deviceInfos = infos;
        this.platform = infos.platform;
        this.version = environment.version
        if (this.platform!='web') {
          App.getInfo().then((appIinfo: AppInfo) => {
            this.version = appIinfo.version || environment.version;
            console.log('TWB version =', this.version)
          });
        }

        // this.networkUp = true;
        // if (this.platform != "web") {
        //   // listening network status
        //   let disconnectSubscription = this.network.onDisconnect().subscribe(() => {
        //     console.log("network was disconnected :-(");

        //     if (this.networkUp) {
        //       this.networkUp = false;
        //       setInterval(()=>{

        //         let msg = this.translate.instant("ERRORS.NO_NETWORK")
        //         console.log('MSG', msg)
        //         this.toast.show(msg, {
        //           color: "danger",
        //           showCloseButton: false,
        //           noduration: true,
        //           cssClass: "nonetwork",
        //         });
        //      },1000);
        //     }
        //   });

        //   let connectSubscription = this.network.onConnect().subscribe(() => {
        //     console.log("network connected!");

        //     this.toast.hide();

        //     this.networkUp = true;
        //     setTimeout(() => {}, 3000);
        //   });

        //   if (this.network.type === "none") {
        //     // not network at startup
        //     this.networkUp = false;
        //     setInterval(()=>{
        //       let msg = this.translate.instant("ERRORS.NO_NETWORK");
        //       console.log('MSG', msg)

        //       this.toast.show(msg, {
        //         color: "danger",
        //         showCloseButton: false,
        //         noduration: true,
        //         cssClass: "nonetwork",
        //       });
        //     },1000)
        //   }
        // }
      
    });
  }

  async getIpInfos(full = true) {
    // let check = 0;
    // let now = new Date().getTime();
    // try {
    //   let str = await this.storage.get('twbip');
    //   if (str) {
    //     check = parseInt(str);
    //   }
    // } catch (e) {}

    if (!this.ipInfos) {
      let url = environment.ipstack.url + "?access_key=" + environment.ipstack.apiKey;
      this.http
        .get(url)
        .toPromise()
        .then((data) => {
          if (data && !data["error"]) {
            this.ipInfos = data;
            //console.log('IPSTACK ',data)
            // try {
            //   this.storage.set('twbip', now + 1 * 3600 * 1000 + '');
            // } catch (e) {

            // }
          }
        })
        .catch((err) => {
          console.log("ipstack error", err);
        });
    }
  }

  log(section, str = null, extra = null) {
    try {
      // do not log admins or in dev
      if (this.user.perms.indexOf("admin") > 0 /*|| !environment.production*/) {
        return;
      }
    } catch (e) {}

    let mom = this.getServerMoment().utc();
    try {
      let ref = "logs/" + mom.format("YYYY/MM/DD/HH");
      let data = {
        timestamp: firebase.database.ServerValue.TIMESTAMP,
        time: mom.format("HH:mm:ss"),
        section: section,
        str: str,
        extra: extra,
        gender: this.user ? this.user.gender : null,
        uid: this.user ? this.user.id : null,
        displayName: this.profile ? this.profile.displayName : null,
        country: this.profile ? this.profile.country : null,
        app: this.isInMobileApp(),
        version: this.version,
        browser: "TWB app",
        ip_city: this.geolocation.city,
        ip_country: this.geolocation.country_iso,
        ub: this.user ? this.user.is_banned : false,
      };

      if (this.deviceInfos) {
        try {
          data["os"] = this.deviceInfos.platform ? this.deviceInfos.platform.toLowerCase() : "?";
          let s = this.deviceInfos.manufacturer + " " + this.deviceInfos.model + " " + this.deviceInfos.osVersion;
          data["device"] = s.toLowerCase();
        } catch (e) {}
      }

      if (this.ipInfos) {
        try {
          if (this.ipInfos.ip) {
            data["ip"] = this.ipInfos.ip;
          }
          if (this.ipInfos.country_code) {
            data["ip_country"] = this.ipInfos.country_code;
          }
          if (this.ipInfos.city) {
            data["ip_city"] = this.ipInfos.city;
          }
        } catch (e) {}
      }
      if (this.user && this.user.id && this.profile && this.profile.id) {
        firebase.database().ref(ref).push(data);
      } else {
        this.http
          .post(environment.api.log, { data: data })
          .toPromise()
          .catch((err) => {
            console.log("ERR log ", err);
          });
      }
    } catch (e) {
      console.log("Log error", e);
    }
  }

  addEvent(uid, type, event, ref_id?, data?) {
    if (type == "visit") {
      // send only 1 visit per session
      if (this._sent_events[type][uid]) {
        return;
      }
      this._sent_events[type][uid] = true;
    }

    let push = {
      from: this.user.id,
      to: uid,
      type: type,
      event: event,
      date: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
      viewed: false,
      ref_id: ref_id || null,
      data: data || null,
      thumb: this.getMemberThumb(this.profile),
    };

    if (!this.user.is_banned) {
      //console.log('addEvent', push);

      firebase.database().ref(`events/${uid}/new`).push(push);
    }
  }

  loadFavorites(uid) {
    const self = this;
    this.fb_favorites = this.db.list(`/favorites/${uid}`).valueChanges();

    this.fb_favorites_sub = this.fb_favorites.subscribe((favs) => {
      let favorites = {};
      for (let i = 0; i < favs.length; i++) {
        favorites[favs[i].member_id || favs[i].$key] = favs[i].date;
      }
      self.favorites = favorites;
    });
  }

  loadPhotosRequests(uid) {
    if (!this.fb_photos_requests_ref) {
      this.fb_photos_requests_ref = firebase.database().ref(`/photos_requests/${uid}`);

      this.fb_photos_requests_ref.on("value", (snaps) => {
        let requests = {};

        snaps.forEach((snap) => {
          let req = snap.val();
          requests[req.member_id || snap.key] = { date: req.date, status: req.status };
        });

        this.photos_requests = requests;
        this.hasPhotosRequestsChanged.next(true);
      });
    }
  }

  arePhotosRequested(uid) {
    if (this.photos_requests[uid]) return this.photos_requests[uid] && this.photos_requests[uid].status == "new" && moment.utc(this.photos_requests[uid].date).add(7, "days").isAfter(moment().utc());
  }

  addInFavorites(uid, favorite, isonline?) {
    let fav = `favorites/${this.user.id}/${uid}`;
    let self = this;

    this.track(favorite ? "add" : "remove", "Favorites");

    if (favorite) {
      firebase
        .database()
        .ref(fav)
        .set({
          date: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
          member_id: uid,
        })
        .then(function (snap) {
          self.addEvent(uid, "favorite", "new");
        });
    } else {
      firebase.database().ref(fav).remove();
      self.addEvent(uid, "favorite", "del");
    }
  }

  getMemberThumb(profile: Profile = null) {
    if (!profile) {
      profile = this.profile;
    }
    let filename = profile ? profile.getProfilePhotoUrl() : "";
    if (!filename || filename.indexOf("assets/") != -1) {
      return filename;
    }
    if (filename.indexOf("/public/") != -1) {
      filename = filename.replace("/public/", "public/");
      return environment.storage.thumbs + encodeURIComponent(filename) + "?alt=media";
    }
    return filename;
  }

  updateProfilePhotoPrivacy() {
    let updates = {};
    updates[`/members/${this.user.id}/profilePhoto/private`] = this.profile.profilePhoto.private;
    firebase.database().ref().update(updates);
  }

  clearNotifications(type) {
    let ref = null;
    if (type == "all") {
      ref = `events/${this.user.id}/notifications`;
    } else {
      ref = `events/${this.user.id}/notifications/${type}`;
    }
    if (ref) {
      firebase.database().ref(ref).remove();
    }
  }

  disableUser(del = false) {
    const self = this;
    this.track("confirm_disable", "My account");

    this.unsubscribe();
    this.user_disabled = true;

    document.body.innerHTML = "";
    if (del) {
      firebase
        .auth()
        .signOut()
        .then((res) => {
          window.location.href = "/";
        });

      return;
    }

    firebase
      .database()
      .ref(`/members/${self.user.id}`)
      .update({ online: false, is_disabled: true })
      .then(function () {
        firebase
          .database()
          .ref(`/users/${self.user.id}`)
          .update({ is_disabled: true })
          .then(function () {
            firebase
              .auth()
              .signOut()
              .then((res) => {
                window.location.href = "/";
              });
          });
      });
  }

  getUserSkew() {
    const self = this;
    let offsetRef = firebase.database().ref(".info/serverTimeOffset");
    offsetRef.on("value", function (snap) {
      self._offset_time = snap.val();
    });
  }

  getServerMoment() {
    return moment().add(this._offset_time, "milliseconds");
  }

  isPremium() {
    if (!this.user || !this.user.id || !this.user.subscription_expire) {
      return false;
    }
    return this._is_premium && this.user.membership == "male_premium" && moment.utc(this.user.subscription_expire).isSameOrAfter(this.getServerMoment().utc());
  }

  isCompleteOrPremium(complete_only = false) {
    if ((this.profile && this.profile.isProfileComplete()) || this.isPremium()) {
      return true;
    }
    if (complete_only) {
      this.toast.show(this.translate.instant("ERRORS.PROFILE_NOT_COMPLETED"));
    } else if (this.user.gender == "male") {
      this.toast.show(this.translate.instant("ERRORS.PROFILE_NOT_COMPLETED_MALE"));
    } else {
      this.toast.show(this.translate.instant("ERRORS.PROFILE_NOT_COMPLETED_FEMALE"));
    }
    return false;
  }

  checkPremium() {
    const self = this;

    this.fb_premium_ref = firebase.database().ref(`premiums/${this.user.id}/is_premium`);

    this.fb_premium_ref.on("value", (snap) => {
      if (snap.exists()) {
        self._is_premium = snap.val();
      }
    });
  }

  privatePhotoRequest(uid, isonline?) {
    if (!this.user.is_banned) {
      firebase
        .database()
        .ref(`/photos_requests/${this.user.id}/${uid}`)
        .set({
          member_id: uid,
          date: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
          status: "new",
          date_auth: null,
        });
      this.addEvent(uid, "photos_requests", "new", this.user.id);
    }
  }

  sharePrivatePhotos(uid, isonline?) {
    const self = this;
    if (!this.user.is_banned) {
      firebase
        .database()
        .ref(`events/${this.user.id}/old/photos_requests/${uid}`)
        .set({
          member_id: uid,
          mode: "accepted",
          date: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        });
      firebase
        .database()
        .ref(`photos_requests/${uid}/${this.user.id}`)
        .update({
          member_id: self.user.id,
          status: "accepted",
          date: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
        });

      this.addEvent(uid, "photos_sharing", "new");
    }
  }

  countEvents(uid) {
    if (!this.fb_events_ref) {
      this.fb_events_ref = firebase.database().ref(`/events/${uid}/notifications`);

      this.fb_events_ref.on("value", (snap) => {
        let events = snap.val();
        let nbEvents = {
          total: 0,
          message: 0,
          favorite: 0,
          photos_requests: 0,
          visit: 0,
          tripinterest: 0,
          photos_sharing: 0,
        };
        for (let cat in events) {
          try {
            if (events[cat].count) {
              let n = events[cat].count;

              if (typeof nbEvents[cat] != "undefined") {
                nbEvents[cat] = n;
                nbEvents.total += n;
              }
            }
          } catch (e) {}
        }
        if (this.nbEvents && this.nbEvents["total"] < nbEvents.total) {
          // this.sound.play();
        }
        this.nbEvents = nbEvents;
      });
    }
  }
  getCoupon() {
    if (!this.user || !this.profile) {
      return false;
    }

    // for male complete
    if (this.profile.gender != "male" || !this.user.emailVerified || this.profile.isProfileComplete()) {
      return false;
    }
    // for non premium without previous subscription
    if (this.isPremium() || this.user.subscription_expire || this.user.paymentMethod) {
      return false;
    }

    // only 48h
    let creationDate = moment(this.profile.creationDate);
    let now = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
    let diff = moment.duration(moment(now).diff(creationDate));
    if (diff.asHours() >= 48) {
      return false;
    }
    return this.coupon;
  }

  isInMobileApp() {
    return this.common.isInMobileApp();
  }

  checkCredits() {
    const self = this;

    this.fb_credit_ref = firebase.database().ref(`credits/members/${this.user.id}`);

    this.fb_credit_ref.on("value", (snap) => {
      if (snap.exists()) {
        self.credits = snap.val();
      }
    });
  }

  useCredits(type, count = 1) {
    firebase.database().ref(`credits/ticks`).push({
      uid: this.user.id,
      type: type,
      count: count,
    });
  }

  hasCredits(type = null) {
    const total = this.credits.payed + this.credits.premium;

    if (type && environment.credits[type]) {
      return total >= environment.credits[type];
    }
    return false;
  }

  canVote() {
    return this.isPremium() || this.profile.isComplete;
  }

  getCredits(cat = null) {
    switch (cat) {
      case "payed":
        return this.credits.payed;
      case "premium":
        return this.credits.premium;
      default:
        return this.credits.payed + this.credits.premium;
    }
  }

  async loadAlertStatus() {
    try {
      let id = this.user.id;
      id = "twbstal" + id.substr(id.length - 4, 4);
      let str = await this.storage.get(id);
      if (str) {
        this.hide_alerts = JSON.parse(str);
      }
    } catch (e) {}
  }

  saveUser(user?: User) {
    const self = this;
    let u = user ? user : this.user;

    if (!u.creationDate) {
      // new user
      u.creationDate = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
    }
    firebase.database().ref(`/users/${u.id}`).set(u);
  }

  saveProfile(profile?: Profile) {
    let p = profile ? profile : this.profile;
    if (!p.creationDate) {
      // new user
      p.creationDate = moment().utc().format("YYYY-MM-DDTHH:mm:ss");
    }
    firebase.database().ref(`/members/${p.id}`).set(p);
  }

  saveProfilePhotoBlob(blob, privat = false, success?, failed?) {
    const self = this;

    if (blob) {
      let storageRef = firebase.storage().ref();

      if (self.profile.profilePhoto && self.profile.profilePhoto.tmp_filename) {
        // delete previous temp photo
        storageRef.child(self.profile.profilePhoto.tmp_filename).delete();
      }

      let filename = new Date().getTime();
      let path = `/profiles/${this.user.id}/${filename}.jpg`;
      let iRef = storageRef.child(path);
      const metadata = {
        contentType: "image/jpeg",
      };
      iRef
        .put(blob, metadata)
        .then((snapshot) => {
          iRef.getDownloadURL().then((downloadURL) => {
            if (!self.profile.profilePhoto) {
              self.profile.profilePhoto = new Photo();
            }
            self.profile.profilePhoto.tmp_filename = path;
            self.profile.profilePhoto.tmp = downloadURL;
            self.profile.profilePhoto.uid = self.user.id;
            self.profile.profilePhoto.private = privat;
            self.profile.profilePhoto.status = "pending";
            self.profile.profilePhoto.reason_approval = null;
            self.profile.profilePhoto.date = moment().utc().format("YYYY-MM-DD HH:mm:ss");
            // updating profile photo
            let updates = {};
            updates[`/members/${self.user.id}/profilePhoto`] = self.profile.profilePhoto;
            updates[`/members/${self.user.id}/isComplete`] = self.profile.isProfileComplete();

            firebase.database().ref().update(updates);

            if (typeof success == "function") {
              success(self.profile.profilePhoto);
            }
          });
        })
        .catch((error) => {
          if (typeof failed == "function") {
            failed(error.message);
          }
        });
    }
  }

  saveVerificationPhotoBlob(blob, privat = false, success?, failed?) {
    const self = this;

    if (blob) {
      let storageRef = firebase.storage().ref();

      if (self.profile.profilePhoto && self.profile.profilePhoto.tmp_filename) {
        // delete previous temp photo
        storageRef.child(self.profile.profilePhoto.tmp_filename).delete();
      }

      let filename = this.user.id + ".jpg";
      let path = `/validations/${filename}`;
      let iRef = storageRef.child(path);
      const metadata = {
        contentType: "image/jpeg",
      };
      iRef
        .put(blob, metadata)
        .then((snapshot) => {
          iRef.getDownloadURL().then((downloadURL) => {
            const validation = {
              url: downloadURL,
              photo_ref: path,
              status: "pending",
              date: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
            };
            firebase.database().ref(`validations/${this.user.id}`).set(validation);

            if (typeof success == "function") {
              success(validation);
            }
          });
        })
        .catch((error) => {
          if (typeof failed == "function") {
            failed(error.message);
          }
        });
    }
  }

  addPhotoBlob(blob, privat = false, success?, failed?) {
    const self = this;

    if (blob) {
      let storageRef = firebase.storage().ref();

      let filename = new Date().getTime();
      let path = `/photos/${this.user.id}/${filename}.jpeg`;
      let iRef = storageRef.child(path);
      const metadata = {
        contentType: "image/jpeg",
      };
      iRef
        .put(blob, metadata)
        .then((snapshot) => {
          iRef.getDownloadURL().then((downloadURL) => {
            if (!self.profile.photos) {
              self.profile.photos = [];
            }
            let photo = new Photo();
            photo.tmp = downloadURL;
            photo.status = "pending";
            photo.private = privat;
            photo.filename = path;
            photo.date = moment().utc().format("YYYY-MM-DD HH:mm:ss");
            photo.uid = self.user.id;
            photo.reason_approval = null;
            firebase
              .database()
              .ref(`/photos/${self.user.id}`)
              .push(photo)
              .then((snap) => {
                const key = snap.key;
                firebase
                  .database()
                  .ref(`/photos/to_approve`)
                  .push({ ref: `photos/${self.user.id}/${key}` });
              });

            if (typeof success == "function") {
              success(photo);
            }
          });
        })
        .catch((error) => {
          if (typeof failed == "function") {
            failed(error.message);
          }
        });
    }
  }

  hasPrivatePhotos() {
    let self = this;
    let photosRef = firebase.database().ref(`/photos/${self.user.id}`);
    photosRef.once("value").then((snapshot) => {
      self.nb_private_photos = 0;
      snapshot.forEach((childSnapshot) => {
        var o = childSnapshot.val();
        if (o.status == "accepted" && o.private) self.nb_private_photos++;
      });
    });
  }

  checkAppVersion() {
    const device = this.common.getAppDevice();
    if (device != "desktop")
      firebase
        .database()
        .ref(`config/apps/${device}`)
        .once("value")
        .then((snap) => {
          if (snap.exists()) {
            const conf = snap.val();
            if (conf.upgrade >= this.version) {
              this.toast.show(this.translate.instant("ERRORS.NEW_VERSION_AVAILABLE"), {  duration: 10000 });
            }
          }
        });
  }

  async forPremiumFeature(config) {
    const alert = await this.alert.create({
      cssClass: "my-custom-class",
      header: config.title,
      message: config.msg,
      buttons: [
        {
          text: this.translate.instant("BUTTONS.BT_GO_PREMIUM"),
          handler: () => {
            this.nav.navigateForward("/settings/membership");
          },
        },
      ],
    });

    alert.present();
  }

  async forVerifiedFeature(config) {
    const alert = await this.alert.create({
      cssClass: "my-custom-class",
      header: config.title,
      message: config.msg,
      buttons: [
        {
          text: this.translate.instant("BUTTONS.BT_VERIFY_PROFILE"),
          handler: () => {
            this.nav.navigateForward("/settings/profile/verification");
          },
        },
      ],
    });

    alert.present();
  }

  setUserInfos(uid) {
    const updates = {};

    if (this.deviceInfos) {
      if (typeof(this.deviceInfos.webViewVersion)=='undefined') {
        this.deviceInfos.webViewVersion = null;
      }
      updates["deviceInfos"] = this.deviceInfos;
      
    }
    if (this.ipInfos) {
      updates["ipInfos"] = this.ipInfos;
    }

    firebase.database().ref(`/users_infos/${uid}`).update(updates);
    firebase.database().ref(`/users/${uid}/platform`).set(this.platform);

    let today = moment().utc().format("YYYY-MM-DD");
    if (this._signin_logged != today) {
      this._signin_logged = today;

      firebase
        .database()
        .ref(`/signins/${uid}/${today}`)
        .transaction(function (count) {
          if (count) {
            return count + 1;
          }
          return 1;
        });
    }
  }
}
