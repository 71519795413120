import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Device, DeviceInfo } from "@capacitor/device";
import firebase from "firebase/compat/app";
import 'firebase/compat/analytics';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  public enabled = true;
  public platform = "web";

  constructor(private router: Router) {
    Device.getInfo().then((info: DeviceInfo) => {
      this.platform = info.platform;

      this.enabled = !!environment.analytics;

      if (this.enabled) {
        firebase.analytics();
      }
    });
  }

  logEvent(event, params = {}) {
    if (this.enabled) {
      firebase.analytics().logEvent(event, params);
    }
  }

  setScreenName(screen) {
    if (this.enabled && screen) {
      firebase.analytics().setCurrentScreen(screen);
    }
  }
}
