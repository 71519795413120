import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  mode = 'capacitor';
  dbname = 'twb';

  constructor() {

  }

  get(key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      switch (this.mode) {
        case 'capacitor':
          Preferences.get({ key: key })
            .then(value => {
              try {
                let v = JSON.parse(value.value);
                resolve(v);
              } catch (e) {
                resolve(null);
              }
            })
            .catch(e => {
              resolve(null);
            });
          break;
        default:
          try {
            let v = JSON.parse(localStorage.getItem(key));
            resolve(v);
          } catch (e) {
            resolve(null);
          }
      }
    });
  }

  set(key: string, value: any): Promise<any> {
    return new Promise((resolve, reject) => {
      switch (this.mode) {
        case 'capacitor':
          Preferences.set({ key: key, value: JSON.stringify(value) })
            .then(v => {
              resolve(value);
            })
            .catch(e => {
              resolve(null);
            });
          break;
        default:
          try {
            localStorage.setItem(key, JSON.stringify(value));
            resolve(value);
          } catch (e) {
            resolve(null);
          }
      }
    });
  }

  remove(key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      switch (this.mode) {
        case 'capacitor':
          Preferences.remove({ key: key })
            .then(v => {
              resolve(true);
            })
            .catch(e => {
              resolve(true);
            });
          break;
        default:
          try {
            localStorage.removeItem(key);
            resolve(true);
          } catch (e) {
            resolve(true);
          }
      }
    });
  }

  clear(): Promise<any> {
    return new Promise((resolve, reject) => {
      switch (this.mode) {
        case 'capacitor':
          Preferences.clear()
            .then(v => {
              resolve(true);
            })
            .catch(e => {
              resolve(true);
            });
          break;
        default:
          try {
            localStorage.clear();
            resolve(true);
          } catch (e) {
            resolve(true);
          }
      }
    });
  }
}
