import { Component, Input, OnInit } from "@angular/core";
import { ToastService } from "../../services/toast.service";

@Component({
  selector: "toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"]
})
export class ToastComponent implements OnInit {
  @Input() message: string = null;

  constructor(public toastService: ToastService) {}

  ngOnInit() {
    this.toastService.show(this.message);
  }
}
