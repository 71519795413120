import { Injectable } from "@angular/core";
import { Device, DeviceInfo } from "@capacitor/device";
//import { NativeAudio } from "@awesome-cordova-plugins/native-audio/ngx";
import { NativeAudio } from '@capacitor-community/native-audio';
import { Platform } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class AudioService {
  private medias = {};
  private audioPlayer: HTMLAudioElement = new Audio();
  private native: boolean = false;
  public volume = 1;

  constructor(private platform: Platform) {
    Device.getInfo().then((infos: DeviceInfo) => {
      this.native = infos.platform == "ios" || infos.platform == "android";
      this.volume = infos.platform == "ios" ? 0.7 : 1;

      this.preload("send_msg", "send_message.mp3");
      this.preload("new_msg", "new_message.mp3");
    });
  }

  preload(key: string, asset: string): void {
    if (this.native) {
      // this.nativeAudio.preloadSimple(key, asset);
      // this.nativeAudio
      //   .preloadComplex(key, asset, this.volume, 1, 0)
      //   .then((res) => {
      //     this.medias[key] = {
      //       asset: asset,
      //       isNative: true,
      //     };
      //   })
      //   .catch((err) => {
      //     console.log("NativeAudio error", err);
      //   });

      NativeAudio
        .preload({
          assetId: key,
          assetPath: asset,
          audioChannelNum: 1,
          isUrl: false
        })
        .then((res) => {
          this.medias[key] = {
            asset: asset,
            isNative: true,
          };
        })
        .catch((err) => {
          console.log("NativeAudio error", err);
        });
    }
  }

  play(key) {
    if (this.native || this.medias[key]) {
      // this.nativeAudio.play(key).then(
      //   (res) => {},
      //   (err) => {
      //     console.log(err);
      //   }
      // );

      NativeAudio.play({
        assetId: key,
      }).then(
        (res) => {},
        (err) => {
          console.log(err);
        }
      );
    } else {
      console.log("Error : sound not found", key);
    }
  }
}
