import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Device, DeviceInfo } from "@capacitor/device";
import { ActionPerformed, PermissionStatus, PushNotifications, PushNotificationSchema, Token } from "@capacitor/push-notifications";
import { NavController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { User } from "./../models/user";
import { AudioService } from "./audio.service";
import { ToastService } from "./toast.service";
import { UserService } from "./user.service";
import { environment } from "../../environments/environment";

// Initialize Firebase
const app = initializeApp(environment.firebase);

@Injectable()
export class NotificationService {
  _token = null;
  user: User = null;
  _handlers: any = {};
  platform = null;

  constructor(
    private toast: ToastService,
    private router: Router,
    private translate: TranslateService,
    private userService: UserService,
    private nav: NavController,
    private zone: NgZone,
    private audio: AudioService
  ) {
    Device.getInfo().then((info) => {
      this.platform = info.platform;
      this.userService.isUserReady.subscribe((ready) => {
        if (ready) {
          this.user = this.userService.user;
          if (info.platform == 'web') {
            this.initWebNotifications();
          } else {
            this.initMobileNotifications();
          }
        }
      });
    });
  }

  initMobileNotifications() {
    this._handlers = [];
    PushNotifications.addListener("registration", (token: Token) => {
      this.updateToken(token.value);
    });

    PushNotifications.addListener("registrationError", (error: any) => {
      console.log("Error on registration: ", error);
    });

    PushNotifications.addListener("pushNotificationReceived", (notification: PushNotificationSchema) => {
      let options = {
        header: notification.title,
        color: "primary",
        cssClass: "fcm-toast",
        duration: 5000,
        buttons: null,
      };
      if (notification.data.cta_mobile) {
        options.buttons = [
          {
            handler: () => {
              this.toast.hide();
              this.router.navigate([notification.data.cta_mobile]);
            },
            role: "cancel",
            text: this.translate.instant("BUTTONS.BT_VIEW"),
          },
        ];
      }
      if (notification.data && notification.data.to_uid == this.userService.user.id) {
        if (this._handlers[notification.data.type]) {
          this.sendToHandlers(notification, notification.data.type);
        } else {
          this.toast.show(notification.body, options);
          if (notification.data.type == "message") {
            this.audio.play("new_msg");
          }
        }
      }
    });

    PushNotifications.addListener("pushNotificationActionPerformed", (notification: ActionPerformed) => {
      try {
        if (notification.actionId == "tap") {
          //const uid = notification.notification.data.uid;
          if (notification.notification.data.cta_mobile) {
            this.nav.navigateForward(notification.notification.data.cta_mobile).then((res) => console.log("nav done", res));
          }

          this.zone.run(() => {
            // refresh the zone, to avoid delay after loading the avatar
          });
        } else {
          console.log("RECEIVED OTHER NOTIFICATION", notification);
        }
      } catch (err) {
        console.log("no uid in notification");
      }
    });

    PushNotifications.requestPermissions().then((result: PermissionStatus) => {
      if (result.receive === "granted") {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });
  }

  initWebNotifications() {
    const messaging = getMessaging(app);

    Notification.requestPermission()
      .then((permission) => {
        if (permission == 'granted') {
          // console.log('Notification permission granted.');
          getToken(messaging).then(token=>{
            this.updateToken(token);
          });
          
        }
      })
      .catch((err) => {
        console.log('Unable to get permission to notify.', err);
      });

    onMessage( messaging, (payload) => {
      let notif = payload.notification;
      notif['data'] = payload.data;
      this.showNotification(notif);
    });
  }

  getFcmToken() {
    return this._token;
  }

  addHandler(type, handler) {
    this._handlers[type] = handler;
  }

  removeHandler(type) {
    delete this._handlers[type];
  }

  sendToHandlers(notification, type = null) {
    for (let h in this._handlers) {
      if (!type || h == type) {
        this._handlers[h](notification);
      }
    }
  }

  updateToken(token) {
    const lang = this.translate.currentLang;
    if (!this.user) {
      return;
    }
    this._token = token;

    firebase.database().ref(`notifications/${this.user.id}/tokens2/${this._token}`).set(lang);
  }

  showNotification(notification) {
    let options = {
      header: notification.title,
      color: "primary",
      cssClass: "fcm-toast",
      duration: 5000,
      buttons: null,
    };
    if (notification.data.cta_mobile) {
      options.buttons = [
        {
          handler: () => {
            this.toast.hide();
            this.router.navigate([notification.data.cta_mobile]);
          },
          role: "cancel",
          text: this.translate.instant("BUTTONS.BT_VIEW"),
        },
      ];
    }
    if (notification.data && notification.data.to_uid == this.userService.user.id) {
      if (this._handlers[notification.data.type]) {
        this.sendToHandlers(notification, notification.data.type);
      } else {
        this.toast.show(notification.body, options);
        if (notification.data.type == "message") {
          this.audio.play("new_msg");
        }
      }
    }
  }
}
