import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { CountriesData, LanguagesData } from 'countries-data';
import * as _ from 'lodash';


@Injectable()
export class ListsService {
  private _lists = {};
  public countries = CountriesData;
  public languages = LanguagesData;
  public languagesShort = {
    ara: { full: 'Arabic' },
    bul: { full: 'Bulgarian' },
    cat: { full: 'Catalan' },
    zho: { full: 'Chinese' },
    hrv: { full: 'Croatian' },
    cze: { full: 'Czech' },
    dan: { full: 'Danish' },
    dut: { full: 'Dutch' },
    eng: { full: 'English' },
    est: { full: 'Estonian' },
    fin: { full: 'Finnish' },
    fra: { full: 'French' },
    deu: { full: 'German' },
    gre: { full: 'Greek' },
    heb: { full: 'Hebrew' },
    hun: { full: 'Hungarian' },
    ind: { full: 'Indonesian' },
    ita: { full: 'Italian' },
    jpn: { full: 'Japanese' },
    kor: { full: 'Korean' },
    lav: { full: 'Latvian' },
    lit: { full: 'Lithuanian' },
    nor: { full: 'Norwegian' },
    per: { full: 'Persian' },
    pol: { full: 'Polish' },
    por: { full: 'Portuguese' },
    ron: { full: 'Romanian' },
    rus: { full: 'Russian' },
    srp: { full: 'Serbian' },
    slk: { full: 'Slovak' },
    slv: { full: 'Slovenian' },
    spa: { full: 'Spanish' },
    swe: { full: 'Swedish' },
    tam: { full: 'Tamil' },
    tha: { full: 'Thai' },
    tur: { full: 'Turkish' },
    ukr: { full: 'Ukrainian' },
    vie: { full: 'Vietnamese' },
    other: { full: 'Other' }
  };

  public eyes = [
    { id: 'black', text: 'black' },
    { id: 'blue', text: 'blue' },
    { id: 'brown', text: 'brown' },
    { id: 'hazel', text: 'hazel' },
    { id: 'gray', text: 'gray' },
    { id: 'green', text: 'green' }
  ];
  public bodyTypes = [
    { id: 'athletic', text: 'athletic' },
    { id: 'curvy', text: 'curvy' },
    { id: 'heavy', text: 'heavy' },
    { id: 'normal', text: 'normal' },
    { id: 'petite', text: 'petite' },
    { id: 'slim', text: 'slim' }
  ];
  public hairColors = [
    { id: 'black', text: 'black' },
    { id: 'blonde', text: 'blonde' },
    { id: 'brown', text: 'brown' },
    { id: 'gray', text: 'gray' },
    { id: 'red', text: 'red' },
    { id: 'bold', text: 'bold' },
    { id: 'other', text: 'other' }
  ];

  public lookingFor = [
    { id: 'adventure', text: 'adventure' },
    { id: 'friends', text: 'friends' },
    { id: 'fun', text: 'fun' },
    { id: 'job', text: 'job' },
    { id: 'mariage', text: 'marriage' },
    { id: 'romance', text: 'romance' }
  ];

  public tripCosts = [
    { id: 'share', text: 'We share' },
    { id: 'ipay', text: 'I pay for both' },
    { id: 'upay', text: 'You pay for me' },
    { id: 'later', text: "Let's decide later" }
  ];

  public tripWith = [
    { id: 'female', text: 'a woman' }, 
    { id: 'male', text: 'a man' }
  ];

  public tripPurpose = [
    { id: 'business', text: 'business' },
    { id: 'citybreak', text: 'city break' },
    { id: 'cruise', text: 'cruise' },
    { id: 'gastronomy', text: 'gastronomy' },
    { id: 'leisure', text: 'leisure' },
    { id: 'roadtrip', text: 'road trip' },
    { id: 'seaside', text: 'by the sea' },
    { id: 'shopping', text: 'shopping' },
    { id: 'sport', text: 'sport' },
    { id: 'other', text: 'other' }
  ];
  
  public genders = [
    { id: 'female', text: 'female' },
    { id: 'male', text: 'male' },
    
  ];

  public height = [];

  public reportReasons = [
    { id: 'offensive', text: 'Offensive content' },
    { id: 'info', text: 'False information' },
    { id: 'money', text: 'Soliciting for money' },
    { id: 'spam', text: 'Spam' },
    { id: 'underage', text: 'Underage' },
    { id: 'duplicate', text: 'Duplicate profile' },
    { id: 'notguy', text: 'Woman in men section' },
    { id: 'notgirl', text: 'Man in women section' },
    { id: 'add', text: 'Promoting other website' },
    { id: 'other', text: 'Other' }
  ];

  public appLanguages = [
    { id: 'en', text: 'english' },
    { id: 'fr', text: 'french' },
    { id: 'it', text: 'italian'},
    { id: 'ro', text: 'romanian' },
    { id: 'de', text: 'german'}
  //  { id: 'sv', text: 'swedish'},
    
  ];
  
  constructor(public translate : TranslateService) {
    for (var i = 210, imin = 120; i >= imin; i--) {
      this.height.push({ id: i, text: i + ' cm' });
    }

    this.translateLists();
    
    
    this.translate.onLangChange.subscribe(() => {
      this.translateLists();
    });
  }

  getList(listname) {
    return this._lists[listname] || this[listname]; 
  }

  translateLists() {
    this.translateList('eyes');
    this.translateList('bodyTypes');
    this.translateList('hairColors');
    this.translateList('lookingFor');
    this.translateList('tripCosts');
    this.translateList('tripWith');
    this.translateList('tripPurpose');
    this.translateList('reportReasons');
    this.translateList('genders');
    this.translateList('appLanguages');
  }

  getCountrySelect() {
    const ret = [];
    let o = null;

    _.map(this.countries, function(o) {
      ret.push({ id: o.iso_3166_1_alpha2, text: o.name.common });
    });

    return _.sortBy(ret, ['text']);
  }

  getSelectedCountries(data) {
    const ret = [];
    let o = null;
    if (!data || data.length == 0) {
      return ret;
    }
    for (let i = 0; i < data.length; i++) {
      o = this.countries[data[i]];
      if (o) {
        ret.push({ id: o.iso_3166_1_alpha2, text: o.name.common });
      }
    }
    return ret;
  }

  getLanguageSelect(mode = 'short') {
    const ret = [];
    let o = null;
    let labels = {};
    let list = mode === 'short' ? this.languagesShort : this.languages;

    _.map(this.languagesShort, function(v, k) {
      labels[v.full] = { iso: [], cnt: 0 };
      ret.push({ id: k, text: v.full });
    });

    _.map(list, function(v, k) {
      if (!labels[v.full]) {
        labels[v.full] = { iso: [], cnt: 0 };
        ret.push({ id: k, text: v.full });
      }
    });

    return _.sortBy(ret, ['text']);
  }

  getSelectedLanguages(data) {
    const ret = [];
    let o = null;
    if (!data || data.length == 0) {
      return ret;
    }

    for (let i = 0; i < data.length; i++) {
      o = this.languages[data[i]];
      if (o) {
        if (o.full == 'US English') {
          o.full = 'English';
        }
        ret.push({ id: data[i], text: o.full });
      }
    }
    return ret;
  }

  getSelectedItems(list, selected) {
    let ret = [],
      item = null;
    const data = this.getList('list');

    if (!selected) {
      return ret;
    }
    for (let i = 0; i < data.length; i++) {
      item = data[i];
      if (selected.indexOf(item.id) !== -1) {
        ret.push(item);
      }
    }
    return ret;
  }

  getIds(a=[]) {
    let ret = [];
    if (!a) {
      return ret;
    }
    a.forEach(item => {
      ret.push[item.id];
    });
    
    return ret;
  }

  getEyes() {
    return this.getList('eyes');
  }

  getHairColors() {
    return this.getList('hairColors');
  }

  getBodyTypes() {
    return this.getList('bodyTypes');
  }

  getLookingFor() {
    return this.getList('lookingFor');
  }

  getAppLanguages() {
    return this.getList('appLanguages');
  }

  itemsToArray(data) {
    let ret = [];
    data.map(item => {
      ret.push(item.id);
    });
    return ret;
  }

  getCountryName(iso) {
    if (this.countries[iso]) {
      return this.countries[iso].name.common;
    }
    return 'unknow';
  }

  getLanguageName(iso) {
    const o = this.languages[iso];
    if (o) {
      if (o.full == 'US English') {
        return 'English';
      }
      return o.full;
    }
    return 'unknow';
  }

  getValue(id, listname) {
    let l = this.getList(listname);
    if (l) {
      const item = _.find(l, { id: id });
      if (item) {
        return item['text'];
      }
    }
    return '';
  }

  translateList(listname) {
    let prefix = `STATIC.LIST.${listname}.`.toUpperCase();
    let ret = [];
    this[listname].forEach(item => {
      let str = this.translate.instant(prefix+item.id);
      if (str && str.indexOf(prefix)===0) {
        str = item.text;
      }
      ret.push( {id: item.id, text: str})
    });
    this._lists[listname] = ret;


  }
}
