import { UserService } from './../../services/user.service';
import { Component, Input, OnInit } from "@angular/core";


@Component({
  selector: "notification-badge",
  templateUrl: "./badge.component.html",
  styleUrls: ["./badge.component.scss"]
})
export class NotificationBadgeComponent implements OnInit {
  @Input() color : string = 'secondary';
  @Input() showIcon : boolean = true;
  constructor(
    public userService: UserService
  ) {}

  ngOnInit() {
    
  }

  getEvents() {
    return this.userService.nbEvents ? this.userService.nbEvents['total'] : 0;
  }
}
