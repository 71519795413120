<ion-header>
  <ion-toolbar color="secondary">
    <ion-buttons slot="start" class="left-buttons">
      <ion-button *ngIf="!close" fill="clear" class="showVerticalMenu" (click)="deviceModeService.showVerticalMenu = !deviceModeService.showVerticalMenu">
        <fa-icon *ngIf="!deviceModeService.showVerticalMenu" size="lg" [icon]="['fal', 'bars']" color="dark"></fa-icon>
        <fa-icon *ngIf="deviceModeService.showVerticalMenu" size="lg" [icon]="['fal', 'outdent']" color="dark"></fa-icon>
      </ion-button>
      <ion-button *ngIf="close" fill="clear" class="" (click)="_close($event)">
        <fa-icon size="lg" [icon]="['fal', 'times']" color="dark"></fa-icon>
      </ion-button>
      <ion-menu-button></ion-menu-button>
      <!-- <ion-back-button text="" *ngIf="back === true" defaultHref="dashboard"></ion-back-button> -->

      <ion-button  *ngIf="back === true" fill="clear"  (click)="goBack()">
        <ion-icon name="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="middle-title" *ngIf="!useContent"
      ><span>{{ title }}</span>
      <ng-container *ngIf="stars && member">
        <member-stars class="hide-empty-stars small-stars" [member]="member"></member-stars>
      </ng-container>
    </ion-title>
    <ion-title class="middle-title" *ngIf="useContent">
      <ng-content></ng-content>
    </ion-title>
    <ion-buttons slot="end" class="right-buttons ion-text-end">
      <ion-button *ngIf="search" slot="start" fill="clear" (click)="_onSearch($event)" class="pull-right">
        <fa-icon size="lg" [icon]="['fal', 'search']" color="dark"></fa-icon>
      </ion-button>
      <ion-button *ngIf="video" slot="start" fill="clear" (click)="_onVideo($event)" class="pull-right">
        <ion-icon name="videocam" color="dark"></ion-icon>
      </ion-button>
      <notification-badge color="primary" class="pull-right"></notification-badge>
      <ion-button *ngIf="menu" fill="clear" slot="end" (click)="_onMenu($event)" class="pull-right">
        <fa-icon class="menu-v" [icon]="['fal', 'ellipsis-v']"></fa-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
