import { StorageService } from './../../services/storage.service';
import { Component, Input, OnInit } from "@angular/core";
import { UserService } from './../../services/user.service';
import * as moment from 'moment';


@Component({
  selector: "alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"]
})
export class AlertComponent implements OnInit {
  @Input() type: string = null;
  @Input() title: string = null;
  @Input() alertId: string = null;
  @Input() close: boolean = null;
  @Input() minify: boolean = null;
  @Input() days: number = null;

  public status = {
  };


  constructor(
    public userService:UserService,
    private storage : StorageService
  ) {
    this.loadAlertStatus();
  }

  async loadAlertStatus() {
    let o = null;

    try {
      let id = this.userService.user.id;
      id = "twbstal" + id.substr(id.length - 4, 4);
      let str = await this.storage.get(id);
      if (str) {
         o = JSON.parse(str);
      }
    } catch (e) {}
    this.status = o ? o : {};

  }

  saveAlertStatus(){
    try {
      let id = this.userService.user.id;
      id = 'twbstal'+id.substr(id.length-4,4);
      this.storage.set(id, JSON.stringify(this.status));
      
    } catch(e) {};
  }

  ngOnInit() {
  }

  isClosed() {
    const closed = this.alertId && this.status[this.alertId] && this.status[this.alertId].status == 'closed';
    if (!closed) {
      return false;
    }
    if (this.days) {
    let ts = this.alertId && this.status[this.alertId] ? this.status[this.alertId].ts: 0;
      return moment(ts).add(this.days, 'days').isAfter(moment());
    }
    return true;

  }

  isMinified() {
    return this.alertId && this.status[this.alertId] && this.status[this.alertId].status == 'minified';
  }

  closeAlert() {
    this.loadAlertStatus();
    if (!this.status[this.alertId]) {
      this.status[this.alertId] = {status:null, ts:null};
    }
    this.status[this.alertId].status = 'closed';
    this.status[this.alertId].ts = new Date().getTime();
    
    this.saveAlertStatus();

  }

  minifyAlert() {
    this.loadAlertStatus();
    this.status[this.alertId] = this.isMinified() ? 'opened' : 'minified';
    this.saveAlertStatus();
  }



}