import { AuthService } from './auth.service';
import { ListsService } from './lists.service';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { ModalController, Platform, ActionSheetController } from '@ionic/angular';
import { ImageViewerComponent } from './../components/image-viewer/image-viewer.component';
import { AnalyticsService } from './analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public scriptStores = [];
  public keyboardChanged = new BehaviorSubject(false);


  constructor(
    public platform: Platform, 
    private modalController: ModalController, 
    private fbanalytics: AnalyticsService, 
    private translate: TranslateService,
    private actionSheetController: ActionSheetController,
    private listsService: ListsService,
    private auth:AuthService
    ) {}

  isInMobileApp() {
    if (this.platform.is('mobile')) {
      return true;
    } else {
      return false;
    }
  }


  getAppDevice() {
    if (this.isInMobileApp()) {
      return this.platform.is('ios') ? 'ios' : 'android';
    }
    return 'desktop';
  }

  loadExternalScript(scriptUrl: string) {
    return new Promise((resolve, reject) => {
      if (this.scriptStores[scriptUrl]) {
        resolve(true);
      }
      const scriptElement = document.createElement('script');
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
      this.scriptStores[scriptUrl] = true;
    });
  }

  gaScreenName(screen) {
    this.fbanalytics.setScreenName(screen);
  }

  gaEvent(event, params = {}) {
    this.fbanalytics.logEvent('event', params);
  }

  async viewImage(src: string, title: string = '', description: string = '') {
    const modal = await this.modalController.create({
      component: ImageViewerComponent,
      componentProps: {
        imgSource: src,
        imgTitle: title,
        imgDescription: description,
      },
      cssClass: 'modal-fullscreen',
      keyboardClose: true,
      showBackdrop: true,
    });

    return await modal.present();
  }

  async viewGallery( gallery: any[]=[], idx:number) {
    const modal = await this.modalController.create({
      component: ImageViewerComponent,
      componentProps: {
        imgSource: gallery[idx].url,
        imgTitle: '',
        imgDescription: '',
        gallery: gallery,
        imgIdx:idx
      },
      cssClass: 'modal-fullscreen',
      keyboardClose: true,
      showBackdrop: true,
    });

    return await modal.present();
  }

  getExternalUrl(type, lang=null) {
    if (!lang) {
      lang = this.translate.currentLang
    }
    if (!lang || !environment.external_urls[lang] ||  !environment.external_urls[lang][type]) {
      lang = 'en';
    }
    return environment.external_urls[lang][type];
  }

  getCurrentLang() {
    return this.translate.currentLang;
  }

  async changeLanguage() {
    let buttons = [];

    let langs = this.listsService.getAppLanguages();
    langs.forEach((l) => {
      buttons.push({
        text: l.text,
        role: 'destructive',
        // icon: 'camera',
        handler: () => {
          this.auth.changeLanguage(l.id);
        },
      });
    });

    buttons.push({
      text: this.translate.instant('BUTTONS.BT_CANCEL'),
      icon: 'close',
      role: 'cancel',
      handler: () => {},
    });
    const actionSheet = await this.actionSheetController.create({
      header: this.translate.instant('LOGIN.CHANGE_LANGUAGE_TITLE') || this.translate.instant('PHOTOS.NEW_PHOTO_MENU'),
      buttons: buttons,
    });
    await actionSheet.present();
  }
}
