import { ListsService } from './lists.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Profile } from './../models/profile';
import { Trip } from './../models/trip';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';
import * as esb from 'elastic-builder';

declare let google: any;

@Injectable()
export class TripsService {
  trips: Observable<any>;
  public isDataReady = new BehaviorSubject([]);

  items = [];
  promoted = null;
  random = null;

  error: string = null;
  criterias = null;
  criterias_default = {
    country: { selected: null, type: 'select' },
    with: null,
    gender: null,
    date_from: {
      type: 'range',
      from: null,
      to: null,
      min: moment().utc().format('YYYY-MM-DD'),
      max: moment().utc().add(5, 'years').format('YYYY-MM-DD'),
    },
  };
  page = 0;
  per_page = 6;
  seed = null;
  stopScrolling = false;
  sort = null;
  clear_cache = 5 * 60 * 1000;
  loading = false;

  constructor(db: AngularFireDatabase, public userService: UserService, private http: HttpClient, private listsServices: ListsService) {
    
  }

  init() {
    if (!this.criterias) {
      this.criterias_default.with = this.userService.profile.gender;
      this.criterias_default.gender = this.userService.profile.gender =="male" ? "female" : "male";

      this.criterias = _.cloneDeep(this.criterias_default);
    }
    
  }

  reset() {
    this.criterias = _.cloneDeep(this.criterias_default);
    this.criterias.with = this.userService.profile && this.userService.profile.gender == 'male' ? 'male' : 'female';
    this.search();
  }

  elasticQuery(config) {
    let boolQuery = esb.boolQuery();
    boolQuery.mustNot(esb.termQuery('published', false));

    for (let k in this.criterias) {
      let v = this.criterias[k];
      if (v) {
        switch (v.type) {
          case 'select':
            if (v.selected) {
              boolQuery.must(esb.termQuery(k, typeof v.selected == 'string' ? v.selected.toLowerCase() : v.selected));
            }
            break;
          case 'range':
            if ((v.from && v.from != v.min) || (v.to && v.to != v.max)) {
              let o = {};
              let range = esb.rangeQuery(k);
              if (v.from) {
                range.gte(v.from);
              }
              if (v.to) {
                range.lte(v.to);
              }
              boolQuery.must(range);
            }
            break;
          default:
            boolQuery.must(esb.termQuery(k, typeof v == 'string' ? v.toLowerCase() : v));
        }
      }
    }

    boolQuery.must(esb.rangeQuery('date_from').gte(moment.utc().format('YYYY-MM-DD')));
    boolQuery.should(esb.rangeQuery('promote_to').gte(moment().utc().format('YYYY-MM-DDTHH:mm:ss')).boost(20));

    let requestBody = null;
    if (config.sort && config.sort != null && !config.sort.random) {
      requestBody = esb.requestBodySearch().query(boolQuery);

      // sort
      config.sort.forEach((s) => {
        requestBody.sort(esb.sort(s.field, s.direction));
      });
    } else {
      // no sort -> random
      if (!this.seed) {
        this.seed = new Date().getTime();
      }

      const fScroreQuery = esb.functionScoreQuery().query(boolQuery).function(esb.randomScoreFunction().seed(this.seed)).boostMode('multiply').boost(5);

      requestBody = esb.requestBodySearch().query(fScroreQuery);
    }

    let body_obj = requestBody.toJSON();
    // pagination
    if (typeof config.from != 'undefined') {
      body_obj['from'] = config.from;
    } else if (typeof config.size != 'undefined') {
      body_obj['from'] = this.page * config.size;
    } else {
      body_obj['from'] = this.page * this.per_page;
    }

    if (typeof config.size != 'undefined') {
      body_obj['size'] = config.size;
    } else {
      body_obj['size'] = this.per_page;
    }
    return body_obj;
  }

  search(config = null) {
    if (!config) {
      config = {};
    }
    this.page = 0;
    this.items = [];
    this.stopScrolling = false;
    this.seed = new Date().getTime();
    if (config.sort) {
      this.sort = config.sort;
    }

    if (!this.criterias.with) {
      this.criterias.with = this.userService.profile && this.userService.profile.gender == 'male' ? 'male' : 'female';
    }
    if (!this.criterias.gender) {
      this.criterias.gender = this.userService.profile && this.userService.profile.gender == 'female' ? 'male' : 'female';
    }
    return this.doSearch(true);
  }

  doSearch(first = false) {
    const self = this;
    this.loading = true;

    return new Promise<Array<any>>((resolve, reject) => {
      let options = self.sort ? { sort: self.sort } : { random: true };
      let queryb = {
        index: environment.elasticsearch.index,
        type: 'trip',
        body: self.elasticQuery(options),
      };
      self.error = null;
      let _int = null

      const ref = firebase.database().ref().child('search');
      const key = ref.child('request').push(queryb).key;
      ref.child('response/' + key).on('value', showResults);
      self.isDataReady.next([]);

      function showResults(snap) {
        if (!snap.exists()) {
          if (!_int) {
            _int = setTimeout(()=>{
              resolve(<Array<any>>self.items);
            }, 1000)
          }
          return false;
        }
        const dat = snap.val().hits;
        if (!dat) {
          return;
        }

        let o = null;

        // if(_int) {
        //   clearTimeout(_int);
        // }
        if (dat && dat.total === 0 && first) {

          self.error = 'trips.NO_RESULTS';
          self.stopScrolling = true;
          self.loading = false;
          resolve(<Array<any>>self.items);

        } else if (dat && dat.hits) {
          let prom = [];

          for (let i = 0; i < dat.hits.length; i++) {
            self.stopScrolling = dat.hits.length < self.per_page;

            let trip = new Trip(dat.hits[i]._source);
            trip.id = dat.hits[i]._id;
            if (trip.uid) {
              prom.push(
                firebase
                  .database()
                  .ref(`members/${trip.uid}`)
                  .once('value')
                  .then((snap) => {
                    if (snap.exists()) {
                      trip.member = new Profile(snap.val());
                      if (trip.member.id && !trip.member.is_disabled && !trip.member.is_banned && !trip.member.is_deleted) {
                        if (!self.items) {
                          self.items=[];
                        }
                        self.items.push(trip);
                        return Promise.resolve(trip);
                      }
                      return Promise.resolve(null);
                    }
                  })
              );
            }
          }
          Promise.all(prom).then((res) => {
            setTimeout(() => {
              self.items = null;
            }, self.clear_cache);
            if (self.items.length == 0) {
              self.error = 'trips.NO_RESULTS';
            }
            self.isDataReady.next(self.items);
            self.loading = false;

            resolve(<Array<any>>self.items);
          });
        }

        // when a value arrives from the database, stop listening
        // and remove the temporary data from the database
        setTimeout(function () {
          snap.ref.off('value', showResults);
          snap.ref.remove();
          self.loading = false;
        }, 1000);
      }
    });
  }

  onScrollDown() {
    if (!this.stopScrolling) {
      this.page++;
      this.doSearch();
    }
  }

  disableGender(gender) {
    if (this.userService.isPremium() || this.userService.profile.verified) {
      return true;
    }
    return gender != this.userService.profile.gender;
  }

  getTripImage(trip: Trip = null) {
    let filename = trip && trip.photo_url ? trip.photo_url : '';
    if (filename.indexOf('assets/') != -1 || filename.indexOf('http') != -1) {
      return filename;
    }

    filename = filename.replace('/public/', 'public/');

    return environment.storage.thumbs + encodeURIComponent(filename) + '?alt=media';
  }

  // findTravellers(place_id, gender, limit = null) {
  //   return new Promise((resolve, reject) => {
  //     let criterias = {
  //       gender: gender,
  //       wantToVisit_ids: place_id,
  //     };
  //     let options = this.sort ? { sort: this.sort } : { random: true };
  //     options['valid_member'] = true;

  //     let body = this.makeQueryBuilderMembers(criterias, options);
  //     if (limit) {
  //       body['size'] = limit;
  //     }

  //     let queryb = {
  //       index: environment.elasticsearch.index,
  //       type: 'member',
  //       body: body,
  //     };

  //     const ref = firebase.database().ref().child('search');
  //     const key = ref.child('request').push(queryb).key;
  //     ref.child('response/' + key).on('value', showResults);

  //     function showResults(snap) {
  //       if (!snap.exists()) {
  //         return false;
  //       }

  //       const dat = snap.val().hits;

  //       let o = null;
  //       let members = [];
  //       if (dat && dat.hits) {
  //         for (let i = 0; i < dat.hits.length; i++) {
  //           let member = new Profile(dat.hits[i]._source);

  //           members.push(member);
  //         }
  //         resolve({ members: members });
  //       }

  //       // when a value arrives from the database, stop listening
  //       // and remove the temporary data from the database
  //       setTimeout(function () {
  //         snap.ref.off('value', showResults);
  //         snap.ref.remove();
  //       }, 1000);
  //     }
  //   });
  // }
  // findLocals(place_id, gender, limit = null) {
  //   return new Promise((resolve, reject) => {
  //     let criterias = {
  //       gender: gender,
  //       place_id: place_id,
  //     };
  //     let options = this.sort ? { sort: this.sort } : { random: true };
  //     options['valid_member'] = true;

  //     let body = this.makeQueryBuilderMembers(criterias, options);
  //     if (limit) {
  //       body['size'] = limit;
  //     }

  //     let queryb = {
  //       index: environment.elasticsearch.index,
  //       type: 'member',
  //       body: body,
  //     };

  //     const ref = firebase.database().ref().child('search');
  //     const key = ref.child('request').push(queryb).key;
  //     ref.child('response/' + key).on('value', showResults);

  //     function showResults(snap) {
  //       if (!snap.exists()) {
  //         return false;
  //       }

  //       const dat = snap.val().hits;

  //       let o = null;
  //       let members = [];
  //       if (dat && dat.hits) {
  //         for (let i = 0; i < dat.hits.length; i++) {
  //           let member = new Profile(dat.hits[i]._source);

  //           members.push(member);
  //         }
  //         resolve({ members: members });
  //       }

  //       // when a value arrives from the database, stop listening
  //       // and remove the temporary data from the database
  //       setTimeout(function () {
  //         snap.ref.off('value', showResults);
  //         snap.ref.remove();
  //       }, 1000);
  //     }
  //   });
  // }

  // makeQueryBuilderMembers(criterias, config) {
  //   let body = bodybuilder();
  //   let filters = [];
  //   let v = null,
  //     f = null;
  //   let q = false;

  //   if (config) {
  //     if (config.random) {
  //       body.query('function_score', {
  //         random_score: {
  //           seed: this.seed,
  //         },
  //       });
  //     }
  //     if (config.sort) {
  //       body.sort(config.sort);
  //     }
  //   }

  //   for (let k in criterias) {
  //     v = criterias[k];
  //     if (v) {
  //       switch (v.type) {
  //         case 'not':
  //           body.notFilter('term', k, v.value);

  //           break;
  //         case 'select':
  //           if (v.selected) {
  //             body.filter('term', k, typeof v.selected[0].id == 'string' ? v.selected[0].id.toLowerCase() : v.selected[0].id);
  //           }
  //           break;
  //         case 'range':
  //           if ((v.from && v.from != v.min) || (v.to && v.to != v.max)) {
  //             let o = {};
  //             if (v.from) {
  //               o['gte'] = v.from;
  //             }
  //             if (v.to) {
  //               o['lte'] = v.to;
  //             }
  //             q = true;
  //             body.query('range', k, o);
  //           }
  //           break;
  //         default:
  //           body.filter('term', k, typeof v == 'string' ? v.toLowerCase() : v);
  //       }
  //     }
  //   }
  //   // body.notFilter('term', 'uid', this.userService.user.id.toLowerCase());
  //   // body.query('range', 'date_from', {'gte': moment.utc().format('YYYY-MM-DD')});
  //   if (!q) {
  //     body.query('match_all');
  //   }
  //   //    body.notFilter('term', 'published', false);

  //   if (config['valid_member']) {
  //     body.notFilter('term', 'id', this.userService.user.id.toLowerCase());
  //     body.notFilter('term', 'is_deleted', true);
  //     body.notFilter('term', 'is_banned', true);
  //     body.notFilter('term', 'is_hidden', true);
  //     body.notFilter('term', 'is_disabled', true);
  //     body.filter('term', 'isComplete', true);
  //   }
  //   let body_obj = body.build();

  //   body_obj['from'] = this.page * this.per_page;
  //   body_obj['size'] = this.per_page;

  //   return body_obj;
  // }

  promotedTrips(gender, travel_with, limit = 10) {
    const self = this;

    return new Promise<Array<any>>((resolve, reject) => {
      if (self.promoted) {
        resolve(self.promoted);
        return;
      }
      let boolQuery = esb.boolQuery();

      boolQuery.mustNot(esb.termQuery('published', false));
      boolQuery.must(esb.termQuery('gender', gender));
      boolQuery.must(esb.termQuery('with', travel_with));
      boolQuery.must(esb.rangeQuery('promote_to').gte(moment().utc().format('YYYY-MM-DDTHH:mm:ss')));
      boolQuery.must(esb.rangeQuery('date_from').gte(moment.utc().format('YYYY-MM-DD')));

      let requestBody = null;
      if (!this.seed) {
        this.seed = new Date().getTime();
      }

      const fScroreQuery = esb.functionScoreQuery().query(boolQuery).function(esb.randomScoreFunction().seed(this.seed)).boostMode('multiply').boost(5);

      requestBody = esb.requestBodySearch().query(fScroreQuery);

      let body_obj = requestBody.toJSON();
      body_obj['from'] = 0;
      body_obj['size'] = limit;

      let queryb = {
        index: environment.elasticsearch.index,
        type: 'trip',
        body: body_obj,
      };

      const ref = firebase.database().ref().child('search');
      const key = ref.child('request').push(queryb).key;

      self.promoted = [];
      ref.child('response/' + key).on('value', showResults);

      function showResults(snap) {
        if (!snap.exists()) {
          return false;
        }

        const dat = snap.val().hits;
        if (!dat) {
          return;
        }

        let o = null;

        if (dat && dat.hits) {
          let prom = [];
          for (let i = 0; i < dat.hits.length; i++) {
            let trip = new Trip(dat.hits[i]._source);
            trip.id = dat.hits[i]._id;

            if (trip.uid) {
              prom.push(
                firebase
                  .database()
                  .ref(`members/${trip.uid}`)
                  .once('value')
                  .then((snap) => {
                    if (snap.exists()) {
                      trip.member = new Profile(snap.val());
                      if (trip.member.id && !trip.member.is_disabled && !trip.member.is_banned && !trip.member.is_deleted) {
                        self.promoted.push(trip);
                      }
                    }
                  })
              );
            }
          }
          Promise.all(prom).then((res) => {
            setTimeout(() => {
              self.promoted = null;
            }, self.clear_cache);
            resolve(<Array<any>>self.promoted);
          });
        } else {
          resolve(<Array<any>>self.promoted);
        }

        // when a value arrives from the database, stop listening
        // and remove the temporary data from the database
        setTimeout(function () {
          snap.ref.off('value', showResults);
          snap.ref.remove();
        }, 1000);
      }
    });
  }

  randomTrips(gender, travel_with, limit = 4) {
    const self = this;

    return new Promise<Array<any>>((resolve, reject) => {
      if (self.random) {
        resolve(self.random);
        return;
      }

      let boolQuery = esb.boolQuery();

      boolQuery.mustNot(esb.termQuery('published', false));
      boolQuery.must(esb.termQuery('gender', gender));
      boolQuery.must(esb.termQuery('with', travel_with));
      boolQuery.mustNot(esb.rangeQuery('promote_to').gte(moment().utc().format('YYYY-MM-DDTHH:mm:ss')));
      boolQuery.must(esb.rangeQuery('date_from').gte(moment.utc().format('YYYY-MM-DD')));

      let requestBody = null;
      if (!this.seed) {
        this.seed = new Date().getTime();
      }

      const fScroreQuery = esb.functionScoreQuery().query(boolQuery).function(esb.randomScoreFunction().seed(this.seed)).boostMode('multiply').boost(5);

      requestBody = esb.requestBodySearch().query(fScroreQuery);

      let body_obj = requestBody.toJSON();
      body_obj['from'] = 0;
      body_obj['size'] = limit;

      let queryb = {
        index: environment.elasticsearch.index,
        type: 'trip',
        body: body_obj,
      };

      const ref = firebase.database().ref().child('search');
      const key = ref.child('request').push(queryb).key;

      self.random = [];
      ref.child('response/' + key).on('value', showResults);

      function showResults(snap) {
        if (!snap.exists()) {
          return false;
        }

        const dat = snap.val().hits;
        if (!dat) {
          return;
        }

        let o = null;

        if (dat && dat.hits) {
          let prom = [];
          for (let i = 0; i < dat.hits.length; i++) {
            let trip = new Trip(dat.hits[i]._source);
            trip.id = dat.hits[i]._id;

            if (trip.uid) {
              prom.push(
                firebase
                  .database()
                  .ref(`members/${trip.uid}`)
                  .once('value')
                  .then((snap) => {
                    if (snap.exists()) {
                      trip.member = new Profile(snap.val());
                      if (trip.member.id && !trip.member.is_disabled && !trip.member.is_banned && !trip.member.is_deleted) {
                        self.random.push(trip);
                      }
                    }
                  })
              );
            }
          }
          Promise.all(prom).then((res) => {
            setTimeout(() => {
              self.random = null;
            }, self.clear_cache);
            resolve(<Array<any>>self.random);
          });
        }

        // when a value arrives from the database, stop listening
        // and remove the temporary data from the database
        setTimeout(function () {
          snap.ref.off('value', showResults);
          snap.ref.remove();
        }, 1000);
      }
    });
  }

  tripsCountries() {
    let countries = this.listsServices.getCountrySelect();

    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref(`trips_countries/${this.userService.profile.gender}`)
        .once('value')
        .then((snap) => {
          if (snap.exists()) {
            let ret = [];
            let c = snap.val();
            for (let iso in c) {
              let pos = _.findIndex(countries, function (o) {
                return o.id == iso;
              });
              let label = countries[pos] ? countries[pos].text : iso;
              ret.push({ id: iso, text: label + ' (' + c[iso] + ')' });
            }
            _.orderBy(ret, ['text'], ['asc']);
            resolve(ret);
          }
        });
    });
  }
}
