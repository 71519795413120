import { Injectable } from "@angular/core";
import { ToastController, IonItemOptions } from "@ionic/angular";
import * as _ from "lodash";

@Injectable({
  providedIn: "root"
})
export class ToastService {

  default = {
    color:'secondary',
    duration: 3000,
    showCloseButton: true,
    closeButtonText: 'Close',
    cssClass:""

  };
  
  constructor(
    public toastController: ToastController
  ) {}

  async show(message, options = null,show_close_button=true) {
    if (!options) {
      options = _.cloneDeep(this.default);
    } else {
      for (let k in this.default) {
        if (typeof options[k] == "undefined") {
          options[k] = this.default[k];
        }
      }
      if (options.noduration) {
        delete options.duration;
      }
    }
    if (options.color=='danger') {
      options.color='secondary'
    }
    options["message"] = message;

    if(show_close_button) {
      let close_button = {
        side: 'start',
        icon: 'close',
        role: 'cancel',
        handler: () => {
        }
      };

      if(options.buttons) {
        options.buttons.push(close_button);
      } else {
        options.buttons=[close_button];
      }
    }

    const toast = await this.toastController.create(options);

    toast.onDidDismiss().then(data => {
      if (data.role == "cancel") {
        if (options.onClose) {
          options.onClose();
        }
      }
    });

    toast.present();
    return toast;
  }


  hide() {
    this.toastController.dismiss().catch(error=>{ console.log('toast hide error', error)});
    
  }

  
}
