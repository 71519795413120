import * as moment from 'moment';

export class User {
  public id: string = null;
  public platform: string = '';
  public authProvider: string = '';
  public displayName: string = '';
  public lastname: string = '';
  public firstname: string = '';
  public country: string = '';
  public email: string = '';
  public language: string = 'en';
  public gender: string = '';
  public avatar: string = '';
  public birthDate: string = null;
  public creationDate: string = null;
  public emailVerified: boolean = false;
  public perms: string[] = ['member'];
  public phone: string = '';
  public membership: string = 'male_free';
  public subscription_expire: string = null;
  public paymentMethod = {};
  public anonymous = false;
  public notifications  = {
    new_msg:true, 
    new_visit:true, 
    new_photorequest:true, 
    new_photoaccept:true, 
    new_favorite:true, 
    new_tripinterest: true,
    newsletter: false,
    notifications:true,
    new_trip: true,
    new_vote: true,
    
  };
  public notifications_frequency = 'daily';
  public is_premium: boolean = false;
  public is_disabled: boolean = false;
  public is_banned: boolean = false;
  public is_hard_banned: boolean = false;
  public is_deleted: boolean = false;
  public is_registered: boolean = false;
  public delete_reason: string = null;
  public reminders = {
    email: null,
    photo: null,
    number: 0
  };
  public validationPhoto = null;
  public validationDate = null;
  public force_to_verify = false;
  public no_copypaste = false;
  public has_trips = false;

  constructor(id?: string | Object) {
    if (typeof id == 'object') {
      this.import(id);
    } else {
      this.id = id;
    }
  }

  exists() {
    return this.id != null;
  }

  import(data) {
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }

  hasPerms(perms) {
    if (typeof perms == 'string') {
      perms = [perms];
    }
    for (let i = 0, imax = perms.length; i < imax; i++) {
      if (this.perms.indexOf(perms[i]) !== -1) {
        return true;
      }
    }
    return false;
  }

  isPremium() {
    
    if (this.subscription_expire ) {

      return moment.utc(this.subscription_expire).isSameOrAfter(moment.utc())

    }
    return false;
  }

  isAnonymous() {
    return this.anonymous && this.isPremium();
  }
}
