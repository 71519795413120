import { ToastService } from './../../services/toast.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Profile } from './../../models/profile';
import { ListsService } from './../../services/lists.service';
import { UserService } from './../../services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'member-card',
  templateUrl: './member-card.component.html',
  styleUrls: ['./member-card.component.scss'],
})
export class MemberCardComponent implements OnInit {
  @Input() profile: Profile;
  @Input() mode: string = 'visitor';
  @Input() isNew: boolean = false;
  @Input() count: number = 0;
  @Input() size: string = null;
  @Input() shared: boolean = false;
  @Input() showFavorite: boolean = true;
  @Output() onViewed = new EventEmitter<any>();
  @Output() onShared = new EventEmitter<any>();
  @Output() onFavorited = new EventEmitter<any>();

  isFavorite = false;
  isOnline = false;
  country = null;

  constructor(
    public userService: UserService, 
    public lists: ListsService,
    private translate:TranslateService, 
    private toast : ToastService
    ) {}

  ngOnInit() {
    this.isOnline = this.profile.isOnline();
    this.country = this.lists.getCountryName(this.profile.country);
    //console.log(this.profile.displayName, this.profile.id)
  }

  click() {
    this.userService.track('view_member', 'Visitors');
    this.onViewed.emit({ id: this.profile.id, uri: this.profile.uri, count: this.count, mode: this.mode });
  }

  getAge() {
    return moment().diff(this.profile.birthDate, 'years');
  }

  memberLink() {
    return this.profile.id;
  }

  toggleFavorite() {
    if (this.userService.isCompleteOrPremium()) {
      if (!this.profile.is_banned) {
        this.isFavorite = !this.isInFavorites();

        this.userService.addInFavorites(this.profile.id, this.isFavorite, this.profile.isOnline());
        this.userService.log('my-favorites', this.isFavorite ? 'add' : 'remove');
        this.onFavorited.emit({ id: this.profile.id, favorited:this.isFavorite })
      }
    }
  }

  isInFavorites() {
    return this.userService.favorites[this.profile.id] != null;
  }

  share(event: Event, bool) {
    event.preventDefault();
    this.onShared.emit({ id: this.profile.id, shared: bool });
  }
}
