// PRODUCTION ENV : replace by DEV
// export const environment = {
//   production: false,
//   pwa : false,
//   version: 2.4,

//   firebase: {
//     apiKey: "AIzaSyBGUAJ_AvJalzzJLUeBQByszyKW6hJjkYw",
//     authDomain: "twb-app-91936.firebaseapp.com",
//     databaseURL: "https://twb-app-91936.firebaseio.com",
//     projectId: "twb-app-91936",
//     storageBucket: "twb-app-91936.appspot.com",
//     messagingSenderId: "457264010977",
//     appId: "1:457264010977:web:0cfccb899d46baef0389a0",
//     measurementId: "G-YTS7PTW3MN"
//   },
//   fcm: {
//     key: 'BCXIWxvm1kZJwL0YR156RdhCsAUiWRlPaz9gvUiEHzKpdIjHUE7LkNjiSsj8yLMtVVUHnzYT0rpsqTAtzFlZaSE',
//   },
//   storage: {
//     thumbs: 'https://firebasestorage.googleapis.com/v0/b/twb-app-91936.appspot.com/o/',
//   },
//   api: {
//     storeValidator: {
//       appstore: 'https://us-central1-twb-app-91936.cloudfunctions.net/appstoreValidatorV2',
//       googleplay: 'https://us-central1-twb-app-91936.cloudfunctions.net/googleplayValidatorV2',
//     },
//     auth: {
//       resetpassword: 'https://us-central1-twb-app-91936.cloudfunctions.net/resetpassword',
//     },
//     log : 'https://us-central1-twb-app-91936.cloudfunctions.net/addlog'

//   },

//   elasticsearch: {
//     index: 'fb_twb',
//   },
//   gmaps: {
//     geocodeURL: 'https://maps.googleapis.com/maps/api/geocode/json',
//     placesURL: 'https://maps.googleapis.com/maps/api/place/details/json',
//     apiKey: 'AIzaSyCW6fmjN9oDpw0vzDdAQnWeGhP3WtP4eWQ',
//   },
//   analytics: true,
//   miles: {
//     profile: 5000,
//     msg: 300,
//     trip: 5000,
//   },
//   securion: {
//     env: 'sandbox',
//   },
//   fiftyonedegrees: {
//     url: 'https://cloud.51degrees.com/api/v1/',
//     apiKey: 'AEUAAAWHNAAASAWADJAGAWNMGJN9K57HEH5Q3GCMZEQDQGAHAV8KVEEJ287NP2SMMXKUTPAHTJ3E9NLUFGUEBWD',
//   },
//   ipstack: {
//     url: 'https://api.ipstack.com/check',
//     apiKey: '25b31ca5253a93e0d9dcad507eafd046',
//   },
//   credits: {
//     videocall: 25,
//     priority_msg: 300,
//     gift: 2000,
//     trip_promo: 5000,
//     profile_promo: 5000,
//   },
//   disable_register: true,
//   external_urls: {
//     en: {
//       terms_and_conditions: 'https://www.tripswithbenefits.com/terms-and-conditions/',
//       privacy_policy: 'https://www.tripswithbenefits.com/privacy-policy/',
//       support: 'https://www.tripswithbenefits.com/contact/',
//       disclaimer: 'https://www.tripswithbenefits.com/disclaimer/',
//       help: 'https://www.tripswithbenefits.com/faq/',
//     },
//     fr: {
//       terms_and_conditions: 'https://www.tripswithbenefits.com/terms-and-conditions/',
//       privacy_policy: 'https://www.tripswithbenefits.com/privacy-policy/',
//       support: 'https://www.tripswithbenefits.com/contact/',
//       disclaimer: 'https://www.tripswithbenefits.com/disclaimer/',
//       help: 'https://www.tripswithbenefits.com/faq/',
//     },
//   },
// };

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.


export const environment = {
  production: false,
  version: 2.4,
  pwa : false,
  firebase: {
    apiKey: 'AIzaSyB3M4_PC8LBHw2BjycBbd_jRcEp9mnt5BY',
    authDomain: 'fir-test-1-bea99.firebaseapp.com',
    databaseURL: 'https://fir-test-1-bea99.firebaseio.com',
    projectId: 'fir-test-1-bea99',
    storageBucket: 'fir-test-1-bea99.appspot.com',
    messagingSenderId: '146738091706',
    appId: '1:146738091706:web:197f34bbfdfc6ea2259483',
    measurementId:"G-0T377TLNXN"
  },
  fcm: {
    key: 'BDq7zpMZdZLPmMo2784OGOL5n0TweetdMnglTtCMNyzuQYjdO-NDIvbk4yb8mTHJFqB4L8tw_WdjNnpVT5LcASc',
  },
  api: {
    storeValidator: {
      appstore: 'https://us-central1-fir-test-1-bea99.cloudfunctions.net/appstoreValidatorV2',
      googleplay: 'https://us-central1-fir-test-1-bea99.cloudfunctions.net/googleplayValidator',
    },
    auth: {
      resetpassword: 'https://us-central1-fir-test-1-bea99.cloudfunctions.net/resetpassword'
    },
    log : 'https://us-central1-fir-test-1-bea99.cloudfunctions.net/addlog'
  },
  storage: {
    thumbs: 'https://firebasestorage.googleapis.com/v0/b/fir-test-1-bea99.appspot.com/o/',
  },
  elasticsearch: {
    index: 'fb_twb_dev',
  },
  gmaps: {
    geocodeURL: 'https://maps.googleapis.com/maps/api/geocode/json',
    placesURL: 'https://maps.googleapis.com/maps/api/place/details/json',
    apiKey: 'AIzaSyDnsusnf7j7DgvaxXSUeh0MBpL3ptrU-nI',
  },
  analytics: false,
  miles: {
    profile: 5000,
    msg: 300,
    trip: 5000,
  },

  securion: {
    env: 'sandbox',
  },
  fiftyonedegrees: {
    url: 'https://cloud.51degrees.com/api/v1/',
    apiKey: 'AEUAAAWHNAAASAWADJAGAWNMGJN9K57HEH5Q3GCMZEQDQGAHAV8KVEEJ287NP2SMMXKUTPAHTJ3E9NLUFGUEBWD',
  },
  ipstack: {
    url: 'https://api.ipstack.com/check',
    apiKey: '25b31ca5253a93e0d9dcad507eafd046',
  },
  credits: {
    videocall: 25,
    priority_msg: 300,
    gift: 2000,
    trip_promo: 5000,
    profile_promo: 5000,
  },
  disable_register: true,
  external_urls: {
    en: {
      terms_and_conditions: 'https://www.tripswithbenefits.com/terms-and-conditions/',
      privacy_policy: 'https://www.tripswithbenefits.com/privacy-policy/',
      support: 'https://www.tripswithbenefits.com/contact/',
      disclaimer: 'https://www.tripswithbenefits.com/disclaimer/',
      help: 'https://www.tripswithbenefits.com/faq/',
    },
    fr: {
      terms_and_conditions: 'https://www.tripswithbenefits.com/terms-and-conditions/',
      privacy_policy: 'https://www.tripswithbenefits.com/privacy-policy/',
      support: 'https://www.tripswithbenefits.com/contact/',
      disclaimer: 'https://www.tripswithbenefits.com/disclaimer/',
      help: 'https://www.tripswithbenefits.com/faq/',
    },
  },
};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

