import { ElementRef, Injectable } from '@angular/core';
import { RateApp } from 'capacitor-rate-app';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class AppRateService {
  last_request = null;
  requests: number = 0;
  timeout: number = 5000;
  _timer = null;
  enabled : boolean = true;

  constructor() {
    try {
      const json = localStorage.getItem('TWBapprate');
      if (json) {
        const data = JSON.parse(json);
        this.last_request = data.last_request || null;
        this.requests = data.requests || 0;
      }
    } catch (e) {}
    if (!this.last_request) {
      this.saveLastRequest(false);
    }
    console.log('AppRateService', this.requests, this.last_request);
  }

  request() {
    return;
    if (!this.enabled) {
      return;
    }
    if (this._timer) {
      clearTimeout(this._timer);

    }

    this._timer = setTimeout(() => {
      const now = moment();
      let delta = null;
      switch (this.requests) {
        case 0:
          delta = moment.duration(3, 'days');
          break;
        case 1:
          delta = moment.duration(1, 'week');
          break;
        default:
          delta = moment.duration(1, 'month');
      }

      const lastRequest = moment(this.last_request);
      const difference = moment.duration(now.diff(lastRequest));

      if (difference >= delta) {
        this.saveLastRequest();
        RateApp.requestReview();
      }
      this.enabled = false;
      this._timer = null;

    }, this.timeout);
  }

  saveLastRequest(inc = true) {
    this.last_request = moment().format('YYYY-MM-DD');
    if (inc) {
      this.requests++;
    }
    localStorage.setItem(
      'TWBapprate',
      JSON.stringify({
        last_request: this.last_request,
        requests: this.requests,
      })
    );
  }
}
