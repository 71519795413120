<ion-card class="alert-card" color="{{type}}"
    *ngIf="!isClosed()" 
    >
    <ion-card-content>
        <strong *ngIf="title">{{title}}</strong>
        <!-- <strong *ngIf="alertId && close" class="pull-right hand" 
            (click)="hideAlert(alertId)">X</strong>  -->
        <div class="pull-right icons">
            <ion-icon name="close" *ngIf="close" class="pull-right hand" (click)="closeAlert()" ></ion-icon>    
            <ion-icon name="remove" *ngIf="minify && !isMinified()" class="hand" (click)="minifyAlert()"></ion-icon>    
            <ion-icon name="add" *ngIf="minify && isMinified()" class="hand" (click)="minifyAlert()"></ion-icon>    
        </div>
        <br  *ngIf="title">
        <ng-content *ngIf="!isMinified()"></ng-content>
       
    </ion-card-content>
  </ion-card>

          