import { CommonService } from './common.service';
import { StorageService } from './storage.service';
import { NavController } from '@ionic/angular';
import { environment } from './../../environments/environment';
import { Trip } from './../models/trip';
import { Photo } from './../models/photo';
import { ListsService } from './lists.service';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Profile } from './../models/profile';
import { UserService } from './user.service';
import * as esb from 'elastic-builder';

declare let google: any;

export interface CachedMember {
  uid: any;
  member: Profile;
  canSeePrivatePhotos: boolean;
  showRequestButton: boolean;
  sharePhotosButton: boolean;
  isFavorite: boolean;
  isOnline: boolean;
  hasPrivateImgs: boolean;
  languages: any[];
  wantToVisit: any[];
  imgs: any[];
  replyRate: number;
  replyRateColor: string;
  trips: Trip[];
}

@Injectable()
export class MembersService {
  trips: Observable<any>;
  public isDataReady = new BehaviorSubject([]);

  items = [];
  top10 = null;
  promoted = null;
  newest = null;
  expire = null;

  error: string = null;
  save_filters: boolean = false;
  criterias = null;
  criterias_search = null;
  criterias_default = {
    isComplete: true,
    gender: null,
    birthDate: {
      min: '18',
      max: '99',
      from: null,
      from_y: '18',
      to: null,
      to_y: '99',
      type: 'range',
    },
    height: { min: '140', max: '210', from: '140', to: '210', type: 'range' },
    bodyType: { selected: null, type: 'select' },
    lookingFor: { selected: null, type: 'select' },
    country: { selected: null, type: 'select' },
    eyes: { selected: null, type: 'select' },
    hairColor: { selected: null, type: 'select' },
    language: { selected: null, type: 'select' },
    online: null,
    has_photos: null,
    verified: null,
    displayName: { type: 'wildcard', value: null },
  };
  page = 0;
  pages_limit = 9;
  seed = null;
  stopScrolling = false;
  sort = null;
  max_pages = null;
  nb_results = 0;
  per_page = 16;
  total_results = 0;
  countries = null;
  languages = null;
  eyes = null;
  bodyTypes = null;
  hairColors = null;
  lookingFor = null;
  visibilityState = false;
  seeds = [];
  _go_next = null;
  loading = false;

  private cachedMemberSubject = new BehaviorSubject<CachedMember>(null);
  public cachedMember = this.cachedMemberSubject.asObservable();

  _nav_list = [];
  _nav_list_name = null;

  clear_cache = 5 * 60 * 1000;

  constructor(public userService: UserService, protected router: Router, private listsService: ListsService, private nav: NavController, private storage: StorageService, private zone: NgZone, private common:CommonService) {
    if (!this.criterias) {
      this.criterias = _.cloneDeep(this.criterias_default);
    }

    this.userService.isUserReady.subscribe((data) => {
      this.criterias.gender = this.userService.user.gender == 'male' ? 'female' : 'male';

      this.storage.get('mfilters').then((filters) => {
        if (filters) {
          this.criterias = filters;
          this.save_filters = true;
        } else {
          this.save_filters = false;
        }
      });
    });
    this.userService.hasPhotosRequestsChanged.subscribe((changed) => {
      let cm = this.cachedMemberSubject.getValue();
      if (cm) {
        if (
          (cm.canSeePrivatePhotos && !this.userService.photos_requests[cm.member.id]) ||
          (!cm.canSeePrivatePhotos && this.userService.photos_requests[cm.member.id] && this.userService.photos_requests[cm.member.id].status == 'accepted')
        ) {
          // something changed in the perms
          cm.canSeePrivatePhotos = this.userService.photos_requests[cm.member.id] && this.userService.photos_requests[cm.member.id].status == 'accepted';
          cm.showRequestButton = !this.userService.photos_requests[cm.member.id];
          cm.imgs = [];
          cm.imgs.push({
            url: cm.member.getProfilePhotoUrl(false),
            perms: 'public',
          });
          this.getPhotos(cm).then(() => {
            this.cachedMemberSubject.next(cm);
            this.zone.run(() => {});
          });
        }
      }
    });
  }

  init() {
    //this.criterias.with = this.userService.profile.gender;
    //if (!this.criterias.gender) {
    //    this.criterias.gender = this.userService.profile && this.userService.profile.gender=='female' ? 'male' : 'female';
    //  }
    //  this.search();
  }

  reset() {
    this.criterias_search = null;
    this.criterias = _.cloneDeep(this.criterias_default);
    this.criterias.gender = this.userService.profile && this.userService.profile.gender == 'female' ? 'male' : 'female';
    this.search();
  }

  makeSeed() {
    let nseed = this.page;
    let seed = null;
    if (!this.seeds[nseed]) {
      this.seeds[nseed] = new Date().getTime();
    }
    this.seed = this.seeds[nseed];
  }

  elasticQuery(config) {
    let boolQuery = esb.boolQuery();

    boolQuery.mustNot(esb.termQuery('is_deleted', true));
    boolQuery.mustNot(esb.termQuery('is_banned', true));
    boolQuery.mustNot(esb.termQuery('is_hidden', true));
    boolQuery.mustNot(esb.termQuery('is_disabled', true));
    boolQuery.must(esb.termQuery('isComplete', true));
    boolQuery.mustNot(esb.termQuery('id', this.userService.user.id.toLowerCase()));

    boolQuery.should(esb.rangeQuery('promote_to').gte(moment().utc().format('YYYY-MM-DDTHH:mm:ss')).boost(20));

    for (let k in this.criterias) {
      let v = this.criterias[k];
      if (v) {
        switch (v.type) {
          case 'select':
            if (v.selected) {
              boolQuery.must(esb.termQuery(k, typeof v.selected == 'string' ? v.selected.toLowerCase() : v.selected));
            }
            break;
          case 'wildcard':
            if (v.value && v.value.trim().length > 0) {
              boolQuery.filter(esb.wildcardQuery(k, '*' + v.value.toLowerCase() + '*'));
            }
            break;
          case 'range':
            if ((v.from && v.from != v.min) || (v.to && v.to != v.max)) {
              let o = {};
              let range = esb.rangeQuery(k);
              if (v.from) {
                range.gte(v.from);
              }
              if (v.to) {
                range.lte(v.to);
              }
              boolQuery.must(range);
            }
            break;
          default:
            boolQuery.must(esb.termQuery(k, typeof v == 'string' ? v.toLowerCase() : v));
        }
      }
    }

    let requestBody = null;
    if (config.sort && config.sort != null) {
      requestBody = esb.requestBodySearch().query(boolQuery);

      // sort
      config.sort.forEach((s) => {
        requestBody.sort(esb.sort(s.field, s.direction));
      });
    } else {
      // no sort -> random
      if (!this.seed) {
        this.seed = new Date().getTime();
      }

      const fScroreQuery = esb.functionScoreQuery().query(boolQuery).function(esb.randomScoreFunction().seed(this.seed));

      requestBody = esb.requestBodySearch().query(fScroreQuery);
    }

    let body_obj = requestBody.toJSON();
    // pagination
    if (typeof config.from != 'undefined') {
      body_obj['from'] = config.from;
    } else if (typeof config.size != 'undefined') {
      body_obj['from'] = this.page * config.size;
    } else {
      body_obj['from'] = this.page * this.per_page;
    }

    body_obj['from'] = this.max_pages ? (this.page % this.max_pages) * this.per_page : this.page * this.per_page;
    body_obj['size'] = this.per_page;

    return body_obj;
  }

  memorizeFilters(b) {
    this.save_filters = b;
    if (b) {
      return this.storage.set('mfilters', this.criterias);
    } else {
      return this.storage.remove('mfilters');
    }
  }

  filter() {
    this.page = 0;
    this.total_results = null;
    this.seeds = [];
    this.items = [];
  }

  search(config = null) {
    if (!config) {
      config = {};
    }
    this.page = 0;
    this.error = null;
    this.items = [];
    this.stopScrolling = false;
    if (config.sort) {
      this.sort = config.sort;
    }
    if (!this.criterias.gender) {
      this.criterias.gender = this.userService.profile && this.userService.profile.gender == 'female' ? 'male' : 'female';
    }

    this.makeSeed();
    return this.doSearch(true);
  }

  doSearch(first = false, infinite = true) {
    const self = this;
    this.loading = true;
    return new Promise((resolve, reject) => {
      let options = self.sort ? { sort: self.sort } : { random: false };
      self.criterias.birthDate.to = self.criterias.birthDate.from_y ? moment().subtract(self.criterias.birthDate.from_y, 'year').format('YYYY-MM-DD') : null;
      self.criterias.birthDate.from = self.criterias.birthDate.to_y ? moment().subtract(self.criterias.birthDate.to_y, 'year').format('YYYY-MM-DD') : null;

      let queryb = {
        index: environment.elasticsearch.index,
        type: 'member',
        body: self.elasticQuery({ random: true }),
      };

      self.error = null;
      const ref = firebase.database().ref().child('search');
      const key = ref.child('request').push(queryb).key;

      if (first || !infinite) {
        self.items = [];
      }
      ref.child('response/' + key).on('value', showResults);
      self.isDataReady.next([]);

      function showResults(snap) {
        if (!snap.exists()) {
          return false;
        }

        const dat = snap.val().hits;
        if (!dat) {
          return;
        }
console.log('SEARCH RESULT', dat)
        let o = null;

        if (dat && dat.total === 0 && first) {
          self.error = 'search.NO_RESULTS';
          self.stopScrolling = true;
          self.loading = false;
        } else if (dat && dat.hits) {
          self.nb_results = dat.total;
          if (!self.total_results) {
            self.total_results = dat.total;
          }
          if (self.max_pages === null) {
            self.max_pages = Math.ceil(dat.total / self.per_page);
          }
          let tmp = [];
          for (let i = 0; i < dat.hits.length; i++) {
            o = new Profile(dat.hits[i]._source);
            if (!o.is_hidden) {
              // if (o.verified) {
              //   tmp.unshift(o);
              // } else {
              tmp.push(o);
              // }
            }
          }
          for (let i = 0; i < tmp.length; i++) {
            self.items.push(tmp[i]);
          }
          self.isDataReady.next(self.items);
          self.setNavList('members', self.items);

          self.stopScrolling = dat.hits.length < self.per_page || self.page > self.pages_limit;

          self.error = self.page > self.pages_limit ? 'pages_limit_reached' : null;
          self.loading = false;
          resolve(tmp);
        } else {
          self.stopScrolling = true;
        }

        // when a value arrives from the database, stop listening
        // and remove the temporary data from the database
        setTimeout(() => {
          snap.ref.off('value', showResults);
          snap.ref.remove();
          self.loading = false;
        }, 1000);

        switch (self._go_next) {
          case 'first':
            self._go_next = null;
            self.router.navigate(['search', 'member', self.items[0].uri, self.page]);
            return;
          case 'last':
            self._go_next = null;
            self.router.navigate(['search', 'member', self.items[self.items.length - 1].uri, self.page]);
            return;
        }
      }
    });
  }

  disableGender(gender) {
    if (this.userService.isPremium() || this.userService.profile.verified) {
      return true;
    }
    return gender != this.userService.profile.gender;
  }

  getNextSearchMemberRoute(uid, dir = 'next', list = null) {
    if (!list) {
      list = 's';
    }
    let index = _.findIndex(this.items, function (o) {
      return o.id == uid;
    });
    if (!this.items || this.items.length == 0) {
      if (!this.criterias.gender) {
        this.criterias.gender = this.userService.profile && this.userService.profile.gender == 'female' ? 'male' : 'female';
      }

      this.router.navigate(['search']);
      return;
    }
    if (dir == 'prev') {
      index--;
      if (index >= 0 && this.items[index]) {
        this.router.navigate(['search', 'member', this.items[index].uri, list]);
        return;
      }
      if (this.page == 0) {
        this.router.navigate(['search']);
        return;
      }
      // need to load previous page
      this._go_next = 'last';
      this.page--;
      this.doSearch();
    } else {
      index++;
      if (index < this.items.length && this.items[index]) {
        this.router.navigate(['search', 'member', this.items[index].uri, list]);
        return;
      }
      // need to load next page
      this._go_next = 'first';
      this.page++;
      this.doSearch();
    }
  }

  setNavList(name, items) {
    this._nav_list = items;
    this._nav_list_name = name;
  }

  navigateInList(uid, dir = 'next') {
    if (!this._nav_list || this._nav_list.length == 0) {
      return;
    }

    // if (dir=="back") {
    //   this.nav.back({animated:true, animationDirection: 'back'});
    //   return;
    // }

    let idx = 'id';
    let index = null;
    let list_uri = null;
    switch (this._nav_list_name) {
      case 'featured':
        list_uri = '/';
        index = _.findIndex(this._nav_list, function (o) {
          return o.id == uid || o.uri == uid;
        });
        break;
      case 'visitors':
        list_uri = '/visitors';
        idx = 'member_id';
        index = _.findIndex(this._nav_list, function (o) {
          return o.member_id == uid;
        });
        break;
      case 'favorites-mine':
        list_uri = '/favorites/tabs/mine';
        idx = 'member_id';
        index = _.findIndex(this._nav_list, function (o) {
          return o.member_id == uid;
        });
        break;
      case 'favorites-their':
        list_uri = '/favorites/tabs/their';
        idx = 'member_id';
        index = _.findIndex(this._nav_list, function (o) {
          return o.member_id == uid;
        });
        break;
      default:
        list_uri = '/search';
        index = _.findIndex(this._nav_list, function (o) {
          return o.id == uid || o.uri == uid;
        });
    }

    // let index = _.findIndex(this._nav_list, function (o) {
    //   return o[idx] == uid;
    // });

    if (index != -1) {
      if (dir == 'next') {
        index++;
        if (index >= this._nav_list.length) {
          this.nav.navigateBack(list_uri, { animated: true, animationDirection: 'forward' });
          return;
        }
        let item = this._nav_list[index];
        this.nav.navigateForward(`member/${item.uri || item.member.uri}`);
      }
      if (dir == 'back' && this.common.getAppDevice()=='desktop') {
        index--;
        if (index < 0) {
          this.nav.navigateBack(list_uri);
          return;
        }
        let item = this._nav_list[index];
        this.nav.navigateBack(`member/${item.uri || item.member.uri}`, { animated: true, animationDirection: 'back' });
      }
      if ( dir == 'backlist' || (dir == 'back' && this.common.getAppDevice()!='desktop')) {
        this.nav.navigateBack(list_uri);
        return;
      }
    }
  }

  getNextListMemberRoute(uid, dir = 'next', list = null) {
    let list_uri = null;
    let idx = null;
    switch (list) {
      case 'm':
        list_uri = ['/'];
        idx = 'id';
        break;
      case 'h':
        list_uri = ['/'];
        idx = 'id';
        break;
      case 'f':
        list_uri = ['/my/favorites'];
        idx = 'member_id';
        break;
      case 'v':
        list_uri = ['/my/visitors'];
        idx = 'member_id';
        break;
      case 'r':
        list_uri = ['/my/shares'];
        idx = 'member_id';
        break;
      default:
        this.router.navigate(['/']);
    }

    if (!this._nav_list || this._nav_list.length == 0) {
      this.router.navigate(list_uri);
      return;
    }
    let index = _.findIndex(this._nav_list, function (o) {
      return o[idx] == uid;
    });

    if (index == -1) {
      this.router.navigate(list_uri);
      return;
    }

    if (dir == 'prev') {
      index--;
    } else {
      index++;
    }
    if (index < 0 || index >= this._nav_list.length) {
      this.router.navigate(list_uri);
      return;
    }
    let m = this._nav_list[index].member || this._nav_list[index];
    if (m.is_banned || m.is_deleted || m.is_disabled || m.is_hidden) {
      return this.getNextListMemberRoute(m.id, dir, list);
    }

    let segment = m.uri;

    this.router.navigate(['member', segment, list]);
  }

  topMembers(gender, limit = 10) {
    const self = this;

    return new Promise((resolve, reject) => {
      if (self.top10) {
        resolve(self.top10);
        return;
      }

      let boolQuery = esb.boolQuery();

      boolQuery.mustNot(esb.termQuery('is_deleted', true));
      boolQuery.mustNot(esb.termQuery('is_banned', true));
      boolQuery.mustNot(esb.termQuery('is_hidden', true));
      boolQuery.mustNot(esb.termQuery('is_disabled', true));
      boolQuery.must(esb.termQuery('isComplete', true));
      boolQuery.must(esb.termQuery('gender', gender));
      boolQuery.must(esb.rangeQuery('promote_to').gte(moment().utc().format('YYYY-MM-DDTHH:mm:ss')));

      let requestBody = null;
      if (!this.seed) {
        this.seed = new Date().getTime();
      }

      requestBody = esb.requestBodySearch().query(boolQuery);

      // sort
      requestBody.sort(esb.sort('score', 'desc'));

      let body_obj = requestBody.toJSON();
      body_obj['from'] = 0;
      body_obj['size'] = limit;

      let queryb = {
        index: environment.elasticsearch.index,
        type: 'member',
        body: body_obj,
      };

      const ref = firebase.database().ref().child('search');
      const key = ref.child('request').push(queryb).key;

      self.top10 = [];
      ref.child('response/' + key).on('value', showResults);

      function showResults(snap) {
        if (!snap.exists()) {
          return false;
        }

        const dat = snap.val().hits;
        if (!dat) {
          return;
        }

        let o = null;

        if (dat && dat.hits) {
          for (let i = 0; i < dat.hits.length; i++) {
            o = new Profile(dat.hits[i]._source);
            if (!o.is_hidden) {
              self.top10.push(o);
            }
          }
          resolve(self.top10);
        }

        // when a value arrives from the database, stop listening
        // and remove the temporary data from the database
        setTimeout(function () {
          snap.ref.off('value', showResults);
          snap.ref.remove();
        }, 1000);
      }
    });
  }

  promotedMembers(gender, limit = 10) {
    const self = this;

    return new Promise<Array<any>>((resolve, reject) => {
      if (self.promoted) {
        resolve(self.promoted);
        return;
      }

      let boolQuery = esb.boolQuery();

      boolQuery.mustNot(esb.termQuery('is_deleted', true));
      boolQuery.mustNot(esb.termQuery('is_banned', true));
      boolQuery.mustNot(esb.termQuery('is_hidden', true));
      boolQuery.mustNot(esb.termQuery('is_disabled', true));
      boolQuery.must(esb.termQuery('isComplete', true));
      boolQuery.must(esb.termQuery('gender', gender));
      boolQuery.must(esb.rangeQuery('promote_to').gte(moment().utc().format('YYYY-MM-DDTHH:mm:ss')));

      let requestBody = null;
      if (!this.seed) {
        this.seed = new Date().getTime();
      }

      const fScroreQuery = esb.functionScoreQuery().query(boolQuery).function(esb.randomScoreFunction().seed(this.seed)).boostMode('multiply').boost(5);

      requestBody = esb.requestBodySearch().query(fScroreQuery);

      let body_obj = requestBody.toJSON();
      body_obj['from'] = 0;
      body_obj['size'] = limit;

      let queryb = {
        index: environment.elasticsearch.index,
        type: 'member',
        body: body_obj,
      };

      const ref = firebase.database().ref().child('search');
      const key = ref.child('request').push(queryb).key;

      self.promoted = [];
      ref.child('response/' + key).on('value', showResults);

      function showResults(snap) {
        if (!snap.exists()) {
          return false;
        }

        const dat = snap.val().hits;
        if (!dat) {
          return;
        }

        let o = null;

        if (dat && dat.hits) {
          for (let i = 0; i < dat.hits.length; i++) {
            o = new Profile(dat.hits[i]._source);
            if (!o.is_hidden) {
              self.promoted.push(o);
            }
          }
          resolve(<Array<any>>self.promoted);
        } else {
          resolve(<Array<any>>self.promoted);
        }

        // when a value arrives from the database, stop listening
        // and remove the temporary data from the database
        setTimeout(function () {
          snap.ref.off('value', showResults);
          snap.ref.remove();
        }, 1000);
      }
    });
  }

  newestMembers(gender, limit = 10) {
    const self = this;

    return new Promise<Array<any>>((resolve, reject) => {
      if (self.newest) {
        resolve(self.newest);
        return;
      }

      let boolQuery = esb.boolQuery();

      boolQuery.mustNot(esb.termQuery('is_deleted', true));
      boolQuery.mustNot(esb.termQuery('is_banned', true));
      boolQuery.mustNot(esb.termQuery('is_hidden', true));
      boolQuery.mustNot(esb.termQuery('is_disabled', true));
      boolQuery.must(esb.termQuery('isComplete', true));
      boolQuery.must(esb.termQuery('gender', gender));

      let requestBody = null;
      if (!this.seed) {
        this.seed = new Date().getTime();
      }

      requestBody = esb.requestBodySearch().query(boolQuery);

      // sort
      requestBody.sort(esb.sort('creationDate', 'desc'));

      let body_obj = requestBody.toJSON();
      body_obj['from'] = 0;
      body_obj['size'] = limit;

      let queryb = {
        index: environment.elasticsearch.index,
        type: 'member',
        body: body_obj,
      };

      const ref = firebase.database().ref().child('search');
      const key = ref.child('request').push(queryb).key;

      self.newest = [];
      ref.child('response/' + key).on('value', showResults);

      function showResults(snap) {
        if (!snap.exists()) {
          return false;
        }

        const dat = snap.val().hits;
        if (!dat) {
          return;
        }

        let o = null;

        if (dat && dat.hits) {
          for (let i = 0; i < dat.hits.length; i++) {
            o = new Profile(dat.hits[i]._source);
            if (!o.is_hidden) {
              self.newest.push(o);
            }
          }
          setTimeout(() => {
            self.newest = null;
          }, self.clear_cache);
          resolve(<Array<any>>self.newest);
        }

        // when a value arrives from the database, stop listening
        // and remove the temporary data from the database
        setTimeout(function () {
          snap.ref.off('value', showResults);
          snap.ref.remove();
        }, 1000);
      }
    });
  }

  expirePremiums(gender, limit = 10) {
    const self = this;

    return new Promise<Array<any>>((resolve, reject) => {
      if (self.expire) {
        resolve(self.expire);
        return;
      }

      let boolQuery = esb.boolQuery();

      boolQuery.mustNot(esb.termQuery('is_deleted', true));
      boolQuery.mustNot(esb.termQuery('is_banned', true));
      boolQuery.mustNot(esb.termQuery('is_hidden', true));
      boolQuery.mustNot(esb.termQuery('is_disabled', true));
      boolQuery.must(esb.termQuery('isComplete', true));
      boolQuery.must(esb.termQuery('gender', gender));

      let from = moment().utc().add(-30, 'days').format('YYYY-MM-DDTHH:mm:ss');
      let to = moment().utc().add(10, 'days').format('YYYY-MM-DDTHH:mm:ss');
      boolQuery.must(esb.rangeQuery('subscription_expire').gte(from).lte(to));

      let requestBody = null;
      if (!this.seed) {
        this.seed = new Date().getTime();
      }

      const fScroreQuery = esb.functionScoreQuery().query(boolQuery).function(esb.randomScoreFunction().seed(this.seed)).boostMode('multiply').boost(5);

      requestBody = esb.requestBodySearch().query(fScroreQuery);

      let body_obj = requestBody.toJSON();
      body_obj['from'] = 0;
      body_obj['size'] = limit;

      let queryb = {
        index: environment.elasticsearch.index,
        type: 'member',
        body: body_obj,
      };

      const ref = firebase.database().ref().child('search');
      const key = ref.child('request').push(queryb).key;

      self.expire = [];
      ref.child('response/' + key).on('value', showResults);

      function showResults(snap) {
        if (!snap.exists()) {
          return false;
        }

        const dat = snap.val().hits;
        if (!dat) {
          return;
        }

        let o = null;

        if (dat && dat.hits) {
          for (let i = 0; i < dat.hits.length; i++) {
            o = new Profile(dat.hits[i]._source);
            if (!o.is_hidden) {
              self.expire.push(o);
            }
          }
          setTimeout(() => {
            self.expire = null;
          }, self.clear_cache);
          resolve(<Array<any>>self.expire);
        }

        // when a value arrives from the database, stop listening
        // and remove the temporary data from the database
        setTimeout(function () {
          snap.ref.off('value', showResults);
          snap.ref.remove();
        }, 1000);
      }
    });
  }

  clearCurrentMember() {
    this.cachedMemberSubject.next(null);
  }

  askPhotosToCurrentMember() {
    let cm = this.cachedMemberSubject.getValue();
    cm.sharePhotosButton = false;
    this.cachedMemberSubject.next(cm);
  }

  loadCurrentMember(id): Promise<CachedMember> {
    return new Promise((resolve, reject) => {
      if (this.cachedMemberSubject.value && this.cachedMemberSubject.value.uid == id) {
        this.cachedMemberSubject.next(this.cachedMemberSubject.value);
        resolve(this.cachedMemberSubject.value);
        return;
      }

      let orderby = 'id';

      if (id.indexOf('_') === 0) {
        // search by uri
        orderby = 'uri';
      }

      let cm: CachedMember = {
        uid: id,
        member: null,
        canSeePrivatePhotos: false,
        showRequestButton: false,
        sharePhotosButton: false,
        isFavorite: false,
        isOnline: false,
        languages: [],
        wantToVisit: [],
        imgs: [],
        trips: [],
        replyRate: null,
        replyRateColor: null,
        hasPrivateImgs: false,
      };

      firebase
        .database()
        .ref('members')
        .orderByChild(orderby)
        .equalTo(id)
        .limitToFirst(1)
        .once('value')
        .then( (snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach( (childSnapshot) => {
              cm.member = new Profile(childSnapshot.val());

              if (cm.member.is_hidden || cm.member.is_disabled) {
                reject('member_disabled_or_hidden');
                return false;
              }
              // if (cm.member.is_banned) {
              //   reject('member_suspended');
              //   return false;
              // }
              if (cm.member.is_deleted) {
                reject('member_deleted');
                return false;
              }
              cm.canSeePrivatePhotos = false;
              cm.wantToVisit = _.keys(cm.member.wantToVisit);

              if (cm.member.language) {
                for (let i = 0; i < cm.member.language.length; i++) {
                  cm.languages.push(this.listsService.getLanguageName(cm.member.language[i]));
                }
              }
              cm.imgs.push({
                url: cm.member.getProfilePhotoUrl(false),
                perms: 'public',
              });
              cm.canSeePrivatePhotos = this.userService.photos_requests[cm.member.id] && this.userService.photos_requests[cm.member.id].status == 'accepted';

              this.getPhotos(cm)
              .then( ()=>{

                this.getTrips(cm);
                this.getReplyRate(cm);

                cm.showRequestButton = !this.userService.photos_requests[cm.member.id];

                cm.isFavorite = this.userService.favorites[cm.member.id] != null;
                cm.isOnline = cm.member.isOnline();

                // check if i share photos with member
                firebase
                  .database()
                  .ref(`photos_requests/${cm.member.id}/${this.userService.profile.id}`)
                  .once('value')
                  .then((snapshot) => {
                    if (snapshot.exists()) {
                      cm.sharePhotosButton = false;
                    } else {
                      cm.sharePhotosButton = true;
                    }
                  });

                // add visit to user events
                if ((this.userService.profile.isProfileComplete() || this.userService.isPremium()) && this.userService.user.id != cm.member.id) {
                  if (!this.userService.isPremium() || !this.userService.user.anonymous) {
                    this.userService.addEvent(cm.member.id, 'visit', 'new');
                  }
                }

                this.cachedMemberSubject.next(cm);
                resolve(cm);
              })
            });
          } else {
            reject('member_doesnt_exist');
          }
        });
    });
  }

  getPhotos(cm: CachedMember) {
    const self = this;
    return firebase
      .database()
      .ref(`/photos/${cm.member.id}`)
      .once('value')
      .then((snaps) => {
        let imgs = [];
        let private_imgs = [];

        snaps.forEach((snap) => {
          let p = new Photo(snap.val());
          if (p.status == 'accepted') {
            let url = !p.private || cm.canSeePrivatePhotos ? p.url : `/assets/img/avatars/${cm.member.gender}_private.png`;

            if (p.private && !cm.canSeePrivatePhotos) {
              private_imgs.push({
                url: url,
                perms: !p.private ? 'public' : 'private',
              });
            } else {
              imgs.push({
                url: url,
                perms: !p.private ? 'public' : 'private',
              });
            }
            if (p.private) {
              cm.hasPrivateImgs = true;
            }
          }
        });
        cm.imgs = _.union(cm.imgs, _.union(imgs, private_imgs));
      });
  }

  getReplyRate(cm: CachedMember) {
    const self = this;
    firebase
      .database()
      .ref(`chats/counts/${cm.member.id}`)
      .once('value')
      .then((snap) => {
        if (snap.exists()) {
          let c = snap.val().total;
          if (c.received) {
            cm.replyRate = Math.round(Math.min(c.sended / c.received, 1) * 100) / 100;
            //cm.replyRate = 0.33;
            cm.replyRateColor = 'secondary';
            // if (cm.replyRate > 0.75) {
            //   cm.replyRateColor = 'success';
            // } else if (cm.replyRate > 0.25) {
            //   cm.replyRateColor = 'warning';
            // }
          }
        } else {
          cm.replyRate = null;
        }
      });
  }

  getTrips(cm: CachedMember) {
    const self = this;

    firebase
      .database()
      .ref('/trips')
      .orderByChild('uid')
      .equalTo(cm.member.id)
      .once('value')
      .then((snaps) => {
        snaps.forEach((snap) => {
          let trip = new Trip(snap.val());
          trip.id = snap.key;
          trip.member = cm.member;
          if (trip.published && moment().utc().format('YYYY-MM-DD') <= trip.date_from) {
            cm.trips.push(trip);
          }
        });
      });
  }
}
