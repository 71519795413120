import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AngularGooglePlaceModule } from 'angular-google-place';
import { CustomFormsModule } from 'ng2-validation';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MomentModule } from 'ngx-moment';
import { Nl2BrPipeModule } from 'nl2br-pipe';
import { ImageViewerComponent } from '../components/image-viewer/image-viewer.component';
import { ToastComponent } from '../components/toast/toast.component';
import { TripCardComponent } from '../components/trip-card/trip-card.component';
import { AlertComponent } from './../components/alert/alert.component';
import { MemberCardComponent } from './../components/member-card/member-card.component';
import { NotificationBadgeComponent } from './../components/notification-badge/badge.component';
import { PageHeaderComponent } from './../components/page-header/header.component';
import { MemberStarsComponent } from './../components/stars/stars.component';


@NgModule({
  entryComponents: [ImageViewerComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslateModule,
    Nl2BrPipeModule,
    MomentModule,
    IonicModule,
    CustomFormsModule,
    AngularGooglePlaceModule,
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    FlexLayoutModule,
  ],
  declarations: [MemberCardComponent, ToastComponent, AlertComponent, NotificationBadgeComponent, PageHeaderComponent, TripCardComponent, MemberStarsComponent, ImageViewerComponent],
  exports: [
    MemberCardComponent,
    FontAwesomeModule,
    TranslateModule,
    Nl2BrPipeModule,
    MomentModule,
    ToastComponent,
    AlertComponent,
    NotificationBadgeComponent,
    PageHeaderComponent,
    TripCardComponent,
    MemberStarsComponent,
    AngularGooglePlaceModule,
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    ImageViewerComponent,
    IonicModule,
    FlexLayoutModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    library.addIconPacks(far);
    library.addIconPacks(fal);
  }
}
