import * as moment from 'moment';

export class Trip {
  public id: string = null;
  public uid: string = null;
  public uidGender: string = null;
  public costs: string = null;
  public country: string = null;
  public creationDate: string = null;
  public date_from: string = null;
  public date_to: string = null;
  public description: string = null;
  public description_tmp: string = null;
  public description_status: string = null;
  public destination: string = null;
  public interested = {};
  public member = null;
  public place_id: string = null;
  public photo_url = null;
  public html_attribution = null;
  public purpose = [];
  public with: string = null;
  public lng = null;
  public lat = null;
  public published = false;
  public reason = null;
  public promote_to = null;
  public photo_position = null;

  constructor(id?) {
    if (id) {
      if (typeof id == 'object') {
        this.import(id);
      } else {
        this.id = id;
      }
    }
  }

  import(data) {
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }

  exists() {
    return this.id !== null;
  }

  isInterested(uid) {
    return this.interested[uid] ? true : false;
  }

  getDuration() {
    return moment(this.date_to).diff(moment(this.date_from), 'days');
  }

  isPromoted() {
    if (this.promote_to) {
      return moment.utc().format('YYYY-MM-DD HH:mm')<=moment.utc(this.promote_to).format('YYYY-MM-DD HH:mm');


    }
    return false;
  }
}
