import { MenuController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { AuthService } from './../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  user: Observable<firebase.User>;
  isLoggedIn: boolean = false;

  constructor(private router: Router, public afAuth: AngularFireAuth, public auth: AuthService, public userService: UserService, private menuCtrl : MenuController) {
    this.user = this.afAuth.authState;
  }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const isLoggedIn = this.auth.isUserLogged();

    return new Promise((resolve, reject) => {
      if (next.data && next.data.unlogged && !isLoggedIn) {
        resolve(true);
      }
      if (isLoggedIn) {
        if (this.userService.profile) {
          resolve(this._check(next, state));
        } else {
          this.userService.isProfileReady.subscribe((data) => {
            if (data) {
              resolve(this._check(next, state));
              
            }
          });
        }
      } else {
        this.userService.redirectUrl = state.url;
        this.router.navigate(['login']);
        resolve(false);
      }
    });
  }

  _check(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.userService.user;

    if (user && (!user.displayName || user.displayName == '')) {
      this.router.navigate(['/register']);
      return true;
    } 

    if (user && user.authProvider == 'email' && !user.emailVerified) {
      this.menuCtrl.enable(false);

      this.router.navigate(['/validateaccount']);
      return true;
    }
    if (user && user.force_to_verify && state.url != '/settings/profile/verification') {
      this.router.navigate(['/settings/profile/verification']);
      return true;
    }
    
    const profile = this.userService.profile;
    if (profile && !profile.isProfileComplete()) {
      switch (profile.gender) {
        case 'female':
          if (!profile.profilePhoto || profile.profilePhoto.status=='') {
            if (state.url != '/registernext') {
              this.router.navigate(['/registernext']);
            }

          }
          break;
        default:

      }
    }

    if (next.data && next.data.roles) {
      const hasPerms = user.hasPerms(next.data.roles);
      if (!hasPerms) {
        this.router.navigate(['404']);
        return false;
      }
    }
    return true;
  }
}
