import { NavController } from '@ionic/angular';
import { DeviceModeService } from './../../services/devicemode.service';
import { Profile } from './../../models/profile';
import { Subscription } from 'rxjs';
import { MembersService } from './../../services/members.service';
import { Component, Input, Output, OnInit, EventEmitter, OnDestroy } from '@angular/core';

@Component({
  selector: 'page-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  @Input() title: string = null;
  @Input() back: boolean = false;
  @Input() close: boolean = false;
  @Input() search: boolean = false;
  @Input() menu: boolean = false;
  @Input() video: boolean = false;
  @Input() stars: boolean = false;
  @Input() useContent: boolean = false;
  @Output() onSearch = new EventEmitter<any>();
  @Output() onMenu = new EventEmitter<any>();
  @Output() onVideo = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<any>();
  // @Output() onBack = new EventEmitter<any>();
  onBack = null;

  sub: Subscription;
  member: Profile;

  constructor(private searchService: MembersService, public deviceModeService: DeviceModeService, private nav: NavController) {}

  ngOnInit() {
    if (this.stars) {
      this.sub = this.searchService.cachedMember.subscribe((cm) => {
        if (cm && cm.member && cm.member.exists()) {
          this.member = cm.member;
        }
      });
    }
  }
  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  _onSearch(event) {
    this.onSearch.emit();
  }

  _onMenu(event) {
    this.onMenu.emit();
  }

  _onVideo(event) {
    this.onVideo.emit();
  }

  _close($event) {
    this.onClose.emit();
  }

  goBack() {
    console.log('goBack')
    if (!this.onBack) {
      this.nav.back();
    } else {
      this.onBack.emit(true);
    }
  }
}
