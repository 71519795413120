import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeviceModeService {
  public resized = new BehaviorSubject(false);
  public orientation = 'portrait';
  public device = 'mobile';

  public showVerticalMenu = true;

  constructor(private deviceService: DeviceDetectorService) {
    if (this.deviceService.isTablet()) {
      this.device = 'tablet';
    } else if (this.deviceService.isDesktop()) {
      try {
        window.screen.orientation.lock('landscape').catch((err) => {
          // console.log('window.screen.orientation.lock not found')
        });
      } catch (e) {}

      this.device = 'desktop';
    } else {
      try {
        window.screen.orientation.lock('portrait-primary').catch((err) => {
          // console.log('window.screen.orientation.lock not found')
        });
      } catch (e) {
      }
    }
    if (window.innerHeight < window.innerWidth) {
      this.orientation = 'landscape';
    }
    window.addEventListener('orientationchange', () => {
      const prev = this.orientation;
      if (window.orientation === 90 || window.orientation === -90) {
        this.orientation = 'landscape';
      } else {
        this.orientation = 'portrait';
      }
      if (prev != this.orientation) {
        this.resized.next(true);
      }
      this.showVerticalMenu = true;
    });

    window.addEventListener('resize', () => {
      const prev = this.device;
      if (this.deviceService.isTablet()) {
        this.device = 'tablet';
      } else if (this.deviceService.isDesktop()) {
        this.device = 'desktop';
      } else {
        this.device = 'mobile';
      }

      if (prev != this.device) {
        this.resized.next(true);
      }
    });
  }

  getDevice() {
    return this.device;
  }
}
