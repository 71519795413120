import { MenuController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { Component, OnInit, Input, Output , EventEmitter} from '@angular/core';

@Component({
  selector: 'member-stars',
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.scss']
})
export class MemberStarsComponent implements OnInit {
  _member = null;
  votes = 0;
  tooltip= "";
  can_go = false;
  @Input() size = "";
  @Input() note = 0;
  @Input() showLink:string = null;
  
  @Input()
  set member(m) {
    const self = this;

    if (m && m.votes) {
      this._member = m;
      this.note = m.votes.avg;
      this.votes = m.votes.n;
      this.tooltip = this.votes>1 ? this.votes+' votes' : '1 vote';

      this.can_go = this.userService.user.id==m.id;
    }
  }


  
  constructor(
    public userService: UserService,
    private nav: NavController,
    private menuCtrl : MenuController
    ) {
    
  }

  ngOnInit() {
  }

  go() {
    if (this.can_go) {
   //   this.router.navigate(['my','votes']);
    }
  }

  goVotes() {
    if(this.showLink  && this.note!=0){
    this.nav.navigateForward(this.showLink);
    this.menuCtrl.close();
    }
  }

}
