import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ModalController, GestureController, IonContent } from '@ionic/angular';
import * as _ from 'lodash';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit {
  @ViewChild('slider') slider;

  @Input() imgSource = null;
  @Input() imgTitle = '';
  @Input() imgDescription = '';
  @Input() gallery = [];
  @Input() imgIdx = 0;

  slideOpts = {
    centeredSlides: 'true',
    //pager: 'true',
    initialSlide: this.imgIdx
  };

  constructor(
    private modalController: ModalController, 
    private gestureCtrl: GestureController,
    private ref: ChangeDetectorRef
    ) {}

  ngOnInit() {
    
    this.slideOpts.initialSlide = this.imgIdx;

    if (this.imgSource && this.gallery.length==0) {
      this.gallery.push( {url:this.imgSource} );
    }

    

  }

  closeModal() {
    this.modalController.dismiss();
  }

  previous() {
    this.slider.slidePrev();
  }

  next() {
    this.slider.slideNext();
  }
}
