<div *ngIf="profile?.id" class="panel widget">
  <div class="panel-body  panel-member {{ size }}">
    <div *ngIf="profile?.getProfilePhotoUrl()" class="twb-avatar-cropper">
      <div class="twb-avatar-panel hand" [ngStyle]="{ 'background-image': 'url(' + userService?.getMemberThumb(profile) + ')' }" (click)="click()"></div>
      <div class="member-top {{ size }}">
        <div class="verified  {{ size }}" >
          <fa-icon class="full-badge" *ngIf="profile?.verified" [icon]="['fas', 'shield']"></fa-icon>
          <fa-icon class="border-badge" *ngIf="profile?.verified" [icon]="['far', 'shield-check']"></fa-icon>
        </div>
        <!-- <div *ngIf="isNew" class="pull-left label label-success">{{ 'MEMBER.NEW' | translate }}</div> -->
        <div class="favorites" *ngIf="showFavorite" (click)="toggleFavorite()">
         <fa-icon class="full-heart" *ngIf="isInFavorites()" [icon]="['fas', 'heart']"></fa-icon>
         <fa-icon class="border-heart" *ngIf="isInFavorites()" [icon]="['far', 'heart']"></fa-icon>
         <fa-icon class="empty-heart" *ngIf="!isInFavorites()" [icon]="['far', 'heart']"></fa-icon>
        </div>
        <!-- <div *ngIf="count > 0" class="pull-right">
        <span class="label  label-outline">{{ count }} visits</span>
      </div> -->
      </div>
      <div class="member-bottom {{ size }}" *ngIf="mode == 'shares'">
        <a class="link refuse" *ngIf="shared" (click)="share($event, false)"><fa-icon  [icon]="['fal', 'times']"></fa-icon>{{ 'BUTTONS.BT_REMOVE'|translate}}</a>
        <a class="link refuse" *ngIf="!shared" (click)="share($event, false)"><fa-icon  [icon]="['fal', 'times']"></fa-icon>{{ 'BUTTONS.BT_REFUSE'|translate}}</a>
        <a class="link accept" *ngIf="!shared" (click)="share($event, true)"><fa-icon  [icon]="['fal', 'check']"></fa-icon>{{ 'BUTTONS.BT_ALLOW'|translate}}</a>
      </div>
    </div>
  </div>
  <div class="member-infos {{ size }} {{ profile?.gender }}">
    <div class="first-line">
      <div class="online-status" [class.online]="profile?.isOnline()"></div>
      <div class="displayname">{{ profile?.displayName }}</div>
      <div class="age">{{ profile?.getAge() }}</div>
    </div>
    <div class="second-line">
      <div class="location"><fa-icon [icon]="['fal', 'map-marker-alt']"></fa-icon>&nbsp;{{ country }}</div>
    </div>
  </div>
</div>
