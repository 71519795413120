import { HttpClient } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Device, DeviceInfo } from "@capacitor/device";
import { IAPProduct, InAppPurchase2 } from "@awesome-cordova-plugins/in-app-purchase-2/ngx";
import { IonicSafeString, LoadingController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import firebase from "firebase/compat/app";
import 'firebase/compat/database';
import * as _ from "lodash";
import { environment } from "./../../environments/environment";
import { CommonService } from "./common.service";
import { ToastService } from "./toast.service";
import { UserService } from "./user.service";

import 'cordova-plugin-purchase';
const {store, ProductType, LogLevel} = CdvPurchase;


declare let Stripe: any;

@Injectable({
  providedIn: "root",
})
export class AppStoreService {
  public product_ids = null;
  private loading = null;
  private _initialized = false;
  private validator_url = null;
  //private securion_token = null;

  //public products: IAPProduct[] = [];
  public products = [];
  public iap_enabled = false;
  public ready = null;
  private _platform = null;
  private _config = { miles: [], plans: [], plans_v2: [], prices: {} };

  store = null;

  constructor(
    private toast: ToastService,
    private router: Router,
    private translate: TranslateService,
    private userService: UserService,
    public platform: Platform,
    //public iap2: InAppPurchase2,
    private zone: NgZone,
    private loadingController: LoadingController,
    private http: HttpClient,
    private common: CommonService
  ) {
    this.product_ids = [];
  }

  init() {
    this.userService.isProfileReady.subscribe((ready) => {
      if (ready) {
        if (!this._initialized) {
          this._initialized = true;
          // console.log('## ##############################################');
          // console.log('## AppStoreService ##############################');
          // console.log('## ##############################################');

          this.platform.ready().then(() => {
            Device.getInfo().then((infos: DeviceInfo) => {
              this.iap_enabled = infos.platform == "ios" || infos.platform == "android";
              this._platform = infos.platform;

              this.getStoreConfig(infos.platform).then((config) => {
                if (this.iap_enabled) {
                  this.validator_url = this._platform == "ios" ? environment.api.storeValidator.appstore : environment.api.storeValidator.googleplay;

                  this.initAppStore();
                } else {
                  this.initWebStore();
                }
              });
            });
          });
        }
      } else {
        this._initialized = false;
        this.products = [];
        this.ready = false;
      }
    });
  }

  getStoreConfig(platform) {

    let store_platform = 'stripe';
    let type_plans =  "SUBSCRIPTION";
    let type_miles =  "CONSUMABLE";
    


    if (platform != 'web') {
      this.store = CdvPurchase.store;
      store_platform = platform=='ios' ? CdvPurchase.Platform.APPLE_APPSTORE : CdvPurchase.Platform.GOOGLE_PLAY;
      type_plans =  ProductType.PAID_SUBSCRIPTION;
      type_miles =  ProductType.CONSUMABLE;
    }

    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref(`config/apps/${platform}/store`)
        .once("value")
        .then((snap) => {
          if (snap.exists()) {
            this._config = snap.val();
            if (this._config.miles) {
              this._config.miles.forEach((p) => {
                this.product_ids.push({
                  id: p,
                  type: type_miles,
                  platform:store_platform,
                  group: 'default'
                } as CdvPurchase.IRegisterProduct);
              });
            }
            if (this._config.plans) {
              
              this._config.plans.forEach((p) => {
                this.product_ids.push({
                  id: p,
                  type: type_plans,
                  platform:store_platform,
                  group: 'default'
                } as CdvPurchase.IRegisterProduct);
              });
            }
            // if (this._config.plans_v2) {              
            //   this._config.plans_v2.forEach((p) => {
            //     this.product_ids.push({
            //       id: p.plan,
            //       offer:p.offer,
            //       type: type_plans,
            //       platform:store_platform,
            //       group: 'default'
            //     } as CdvPurchase.IRegisterProduct);
            //   });
            // }

            resolve(this._config);
          } else {
            reject("no-store-config");
          }
        });
    });
  }

  isSubscription(p) {
    return p.type === ProductType.PAID_SUBSCRIPTION || p.type === "subscription";
  }

  isProduct(p) {
    return p.type === ProductType.CONSUMABLE || p.type === "product";
  }

  getConfig(key) {
    return this._config[key] ? this._config[key] : [];
  }

  initAppStore() {

    this.store = CdvPurchase.store;

    if (!environment.production) {
      this.store.verbosity = LogLevel.DEBUG;

      this.store.autoFinishTransactions = true;
    }

    this.store.validator = (product, cb) => {
      console.log('Transaction validator', product, cb)
      this.validator(product, cb);
    };

    this.store.applicationUsername = () => {
      return this.userService.user ? this.userService.user.id : "unknow_user";
    };

    // Track all store errors
    this.store.error((err) => {
      console.log("Store Error ", err);
      this.dismissLoader();
    });

    this.product_ids.forEach((p) => {
      // purchased approved
      this.store.when()
      .approved((transaction: CdvPurchase.Transaction) => {
        console.log(">>>>>>>>>>>>>>>>>> Purchase approved",  transaction);
        transaction.verify();
      })
      .verified((receipt: CdvPurchase.Receipt) => {
        console.log(">>>>>>>>>>>>>>>>>> Purchase verified",  receipt);
        receipt.finish();
        this.dismissLoader();
      })
      .unverified(receipt => {
        console.log('unverified payload')
        console.log(receipt.payload);
        console.log('unverified receipt')
        console.log(receipt.receipt);
      })


      this.store.register(p);
    });
    
      

    const platforms = this._platform=='ios' ? [CdvPurchase.Platform.APPLE_APPSTORE] : [CdvPurchase.Platform.GOOGLE_PLAY];
    this.store.initialize(platforms);

    // Run some code only when the store is ready to be used
    this.store.ready(() => {
      //console.log('Store is ready');
      this.products = this.store.products;
      console.log('#################### PRODUCTS');
      console.log(this.products);
      console.log('#################### END PRODUCTS');

      this.ready = true;
      this.zone.run(() => {
        // refresh the zone, to avoid delay
      });
    });
  }

  getProductInfo(product) {
    let id = product.id.split(".").pop();
    return "STATIC.PRODUCTS." + id;
  }

  findProduct(id) {
    return _.find(this.products, { id: id });
  }

  refreshStore() {
    this.store.updateh();
  }

  validator(product, callback) {
    // if (product.alias == "application") {
    //   callback(true, {
    //     transaction: {
    //       uid: this.userService.user.id,
    //     },
    //   });
    //   return true;
    // }

    this.http
      .post(this.validator_url, { uid: this.userService.user.id, product: product })
      .toPromise()
      .then((res:any) => {
        if (res.ok) {
          console.log('purchase Validator : Verified');
          if (!res.data) {
            res.data = {};
          }
          res.data.id = product.id;
          if (!res.data.transaction) {
            res.data.transaction = product.transaction;
          }
          callback( res);
        } else {
          console.log('purchase Validator : NOT Verified',res["data"].code)
          callback(res);
        }
        this.dismissLoader();
      })
      .catch((err) => {
        callback(false, "err_no_validator");
        this.dismissLoader();
      });
  }

  getProducts() {
    return this.products;
  }

  dismissLoader() {
    if (this.loading) {
      this.loading.dismiss();
    }
  }

  async buy(product) {
    if (this._platform != "web") {
      this.loading = await this.loadingController.create({
        message: new IonicSafeString(this.translate.instant("APP.PLEASE_WAIT")),
        duration: 120000,
      });

      await this.loading.present();

      const offer = product.offers[0];
      console.log('Offer',offer, product)
      if (offer) {
        offer.order()
        .then(error => {
          if (error) {
            if (error.code === CdvPurchase.ErrorCode.PAYMENT_CANCELLED) {
              // Purchase flow has been cancelled by user
              console.log('Purchase flow has been cancelled by user')
            }
            else {
              // Other type of error, check error.code and error.message
              console.log('Purchase flow Error', error.code, error.message)
            }
          }
          this.dismissLoader();
        });
      } else {
        this.dismissLoader();
      }

      //this.store.order(product.id, { uid: this.userService.user.id });
    } else {
      this.loading = await this.loadingController.create({
        message: new IonicSafeString(this.translate.instant("APP.PLEASE_WAIT")),
        duration: 120000,
      });

      await this.loading.present();
      this.stripeCheckout(product)
        .then((session) => {
          console.log("Session", session);
          return this.stripeCheckout2(product, session);
        })
        .catch((err) => {
          this.dismissLoader();
          this.toast.show(this.translate.instant("MEMBERSHIP.CHECKOUT_STRIPE_ERROR", { color: "danger" }));
        });

      // forward to stores
      // this.router.navigate(['settings/membership/stores']);
    }
  }

  async manageSubscriptions() {
    this.store.manageSubscriptions();
  }

  // restorePurchases() {
  //   this.iap
  //     .restorePurchases()
  //     .then(purchases => {
  //       purchases.forEach(purchase => console.log(purchase.productId + ' should be restored'));
  //       // unlock the relevant feature based on this product id
  //     })
  //     .catch(err => console.log(err));
  // }

  isMilesProduct(product) {
    return product.id.indexOf("twb.miles.") != -1;
  }

  isSubscriptionProduct(product) {
    return product.id.indexOf("twb.plans.") != -1;
  }

  private loadExternalScript(scriptUrl: string) {
    return new Promise((resolve, reject) => {
      const scriptElement = document.createElement("script");
      scriptElement.src = scriptUrl;
      scriptElement.onload = resolve;
      document.body.appendChild(scriptElement);
    });
  }

  initWebStore() {
    this.loadExternalScript("https://js.stripe.com/v3/").then(() => {});

    this.products = [
      // {
      //   id: 'twb.plans.7d',
      //   alias: '7d',
      //   type: 'paid subscription',
      //   title: this.translate.instant('STATIC.STORE.PRODUCT_TITLE_7D'),
      //   description: this.translate.instant('STATIC.STORE.PRODUCT_DESC_7D'),
      //   price: '7 €',
      //   currency: 'EURO',
      //   canPurchase: true,
      //   billingPeriod: 7,
      //   billingPeriodUnit: 'Day',
      //   valid: true,
      //   priceMicros:7000000
      // },
      {
        id: "twb.plans.1d",
        alias: "1d",
        type: "paid subscription",
        title: this.translate.instant("STATIC.STORE.PRODUCT_TITLE_1D"),
        description: this.translate.instant("STATIC.STORE.PRODUCT_DESC_1D"),
        price: "1 €",
        currency: "EURO",
        canPurchase: true,
        billingPeriod: 1,
        billingPeriodUnit: "Day",
        valid: true,
        priceMicros: 1000000,
      },
      {
        id: "twb.plans.30d",
        alias: "30d",
        type: "paid subscription",
        title: this.translate.instant("STORE.PRODUCT_TITLE_30D"),
        description: this.translate.instant("STORE.PRODUCT_DESC_30D"),
        price: "19.99 €",
        currency: "EURO",
        canPurchase: true,
        billingPeriod: 1,
        billingPeriodUnit: "Month",
        valid: true,
        priceMicros: 19990000,
      },
      {
        id: "twb.plans.90d",
        alias: "90d",
        type: "paid subscription",
        title: this.translate.instant("STORE.PRODUCT_TITLE_90D"),
        description: this.translate.instant("STORE.PRODUCT_DESC_90D"),
        price: "49.99 €",
        currency: "EURO",
        canPurchase: true,
        billingPeriod: 3,
        billingPeriodUnit: "Month",
        valid: true,
        priceMicros: 49990000,
      },
      {
        id: "twb.plans.180d",
        alias: "180d",
        type: "paid subscription",
        title: this.translate.instant("STORE.PRODUCT_TITLE_180D"),
        description: this.translate.instant("STORE.PRODUCT_DESC_180D"),
        price: "99.99 €",
        currency: "EURO",
        canPurchase: true,
        billingPeriod: 6,
        billingPeriodUnit: "Month",
        valid: true,
        priceMicros: 99990000,
      },
      // MILES
      {
        id: "twb.miles.100",
        alias: "100miles",
        title: this.translate.instant("STORE.PRODUCT_TITLE_100"),
        description: this.translate.instant("STORE.PRODUCT_DESC_100"),
        price: "5.99 €",
        currency: "EURO",
        canPurchase: true,
        valid: true,
        priceMicros: 5990000,
      },
      {
        id: "twb.miles.800",
        alias: "800miles",
        title: this.translate.instant("STORE.PRODUCT_TITLE_800"),
        description: this.translate.instant("STORE.PRODUCT_DESC_800"),
        price: "24.99 €",
        currency: "EURO",
        canPurchase: true,
        valid: true,
        priceMicros: 24990000,
      },
      {
        id: "twb.miles.300",
        alias: "300miles",
        title: this.translate.instant("STORE.PRODUCT_TITLE_300"),
        description: this.translate.instant("STORE.PRODUCT_DESC_300"),
        price: "13.99 €",
        currency: "EURO",
        canPurchase: true,
        valid: true,
        priceMicros: 13990000,
      },
    ];

    setTimeout(() => {
      this.ready = true;
    }, 2000);
  }

  goToStore() {
    this.store.manageSubscriptions();
  }

  stripeCheckout(product) {
    return new Promise((resolve, reject) => {
      const rep_id = this.userService.user.id + new Date().getTime();

      this.userService.log("membership checkout", "stripe");

      let plan_id = product.type == "paid subscription" ? product.id : null;
      let prod_id = product.type != "paid subscription" ? product.id : null;
      let sessionId = null;

      firebase
        .database()
        .ref(`payments/stripe/checkout`)
        .push({
          plan: plan_id,
          product: prod_id,
          alias: product.alias,
          uid: this.userService.user.id,
          email: this.userService.user.email,
          coupon: null,
          rep_id: rep_id,
        })
        .then((result) => {
          firebase
            .database()
            .ref(`payments/answers/${rep_id}`)
            .on("value", (snap) => {
              if (snap.exists()) {
                snap.ref.off("value");
                // remove the newly created token
                snap.ref.remove();
                resolve(snap.val());
              }
            });
        });
    });
  }

  stripeCheckout2(product: IAPProduct, session) {
    return new Promise((resolve, reject) => {
      const rep_id = this.userService.user.id + new Date().getTime();
      this.userService.log("my-account checkout2", "stripe");

      const stripe = Stripe(session.publishableKey);

      stripe.redirectToCheckout({ sessionId: session.sessionId });
    });
  }

  stripeCancel() {
    // cancel the subscription

    return new Promise((resolve, reject) => {
      const rep_id = this.userService.user.id + new Date().getTime();

      this.userService.track("confirm_cancel_subscription", "My account");
      const body = {
        uid: this.userService.user.id,
      };

      firebase
        .database()
        .ref(`payments/${this.userService.user.paymentMethod["type"]}/cancel`)
        .push({
          uid: this.userService.user.id,
          rep_id: rep_id,
        })
        .then((result) => {
          firebase
            .database()
            .ref(`payments/answers/${rep_id}`)
            .on("value", (snap) => {
              if (snap.exists()) {
                const data = snap.val();
                snap.ref.off("value");
                // remove the newly created token
                snap.ref.remove();

                //console.log('REPONSE CANCEL=',data)

                if (data.canceled) {
                  // show message subscription canceled
                  resolve(true);
                } else {
                  // show message subscription cancel error
                  resolve(false);
                }
              }
            });
        });
    });
  }

  couponCheckout(coupon) {
    return new Promise((resolve, reject) => {
      const rep_id = this.userService.user.id + new Date().getTime();

      firebase
        .database()
        .ref(`payments/coupon/checkout`)
        .push({
          coupon: coupon,
          uid: this.userService.user.id,
          email: this.userService.user.email,
          rep_id: rep_id,
        })
        .then((result) => {
          firebase
            .database()
            .ref(`payments/answers/${rep_id}`)
            .on("value", (snap) => {
              if (snap.exists()) {
                const token = snap.val();
                snap.ref.off("value");
                // remove the newly created token
                snap.ref.remove();

                if (!token.success) {
                  this.userService.log("my-account coupon", "invalid " + coupon);
                  resolve(false);
                } else {
                  this.userService.log("my-account subscription", "coupon " + coupon);

                  resolve(true);
                }
              }
            });
        });
    });
  }
}
