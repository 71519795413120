
<div class="trip">
  <div class="image-cropper" [class.pending]="mode=='mine' && trip?.description_status!='approved'"
  [ngStyle]="{ 'background-image': 'url(' + tripsService.getTripImage(trip) + ')' }" (click)="click('img')">

    <div *ngIf="trip?.html_attribution" class="html-attribution" [innerHTML]="trip?.html_attribution"></div>
  </div>

  <div class="content {{mode}}">
    <ion-avatar class="owner" [class.mine]="mode == 'mine'" slot="start" [routerLink]="['member', trip?.member?.uri]">
      <img [src]="userService?.getMemberThumb(trip?.member)" (click)="click()" />
    </ion-avatar>

    <div *ngIf="mode == 'mine'" class="pull-right menu">
      <fa-icon slot="start" [icon]="['fal', 'ellipsis-v']" (click)="menu()"></fa-icon>
    </div>

    <div class="date" *ngIf="mode != 'mine'">
      <span>
        <fa-icon slot="start" [icon]="['fal', 'calendar']"></fa-icon>
        {{ trip.date_from }} - {{ trip.getDuration() }} {{ 'TRIP.TRIP_DURATION_DAYS' | translate }}
      </span>
      <div class="pull-right">
        <ion-button *ngIf="showBtView" class="bt-interested" (click)="click()" size="small" color="secondary">{{ 'BUTTONS.BT_VIEW' | translate }}</ion-button>
        <!-- <ion-button *ngIf="showBtInterested" class="bt-interested" [disabled]="disable_interest || trip?.isInterested(userService?.user?.id)" (click)="iAmInterested()" size="small" color="secondary">{{
          'MEMBER.PROFILE.BT_INTERESTED_TRIP' | translate
        }}</ion-button> -->
            </div>
    </div>
    <span class="title">{{ trip.destination }}</span>

    <div class="date mine" *ngIf="mode == 'mine'">
      <span>
        <fa-icon slot="start" [icon]="['fal', 'calendar']"></fa-icon>
        {{ trip.date_from }} - {{ trip.getDuration() }} {{ 'TRIP.TRIP_DURATION_DAYS' | translate }}
      </span>
    </div>
    <div *ngIf="mode != 'mine'" class="description"  [class.short]="shortDescription" [innerHTML]="trip.description | nl2br"></div>
    <div *ngIf="mode == 'mine'" class="description"  [innerHTML]="(trip.description_tmp || trip.description) | nl2br"></div>

    <div *ngIf="mode == 'mine' && trip?.description_status == 'pending'" class="validation pending">{{ 'TRIP.VALIDATION_PENDING' | translate }}</div>
    <div *ngIf="mode == 'mine' && trip?.description_status == 'rejected'" class="validation rejected">
      {{ 'TRIP.VALIDATION_REJECTED' | translate }}<span>{{ 'STATIC.TRIPS.' + trip.reason | translate }}</span>
    </div>

    <div *ngIf="showDetails" fxLayout="row" fxLayoutGap="10px">
      <div fxFlex="33%" class="trip-infos">
        <label>{{ 'TRIP.GOING_WITH' | translate }}</label>
        <span>{{ listsService.getValue(trip.with, 'tripWith') }}</span>
      </div>
      <div fxFlex="33%" class="trip-infos">
        <label>{{ 'TRIP.WHO_PAY' | translate }}</label>
        <span>{{ listsService.getValue(trip.costs, 'tripCosts') }}</span>
      </div>

      <div fxFlex="33%" class="trip-infos">
        <label>{{ 'TRIP.PURPOSE' | translate }}</label>
        <span>{{ listsService.getValue(trip.purpose[0], 'tripPurpose') }}</span>
      </div>
    </div>

    <div class="interest-button" *ngIf="showDetails && mode != 'mine'" >
      <ion-button *ngIf="showBtInterested" class="bt-interested pull-right" [disabled]="disable_interest || trip?.isInterested(userService?.user?.id)" (click)="iAmInterested()" size="small" color="secondary">{{
        'MEMBER.PROFILE.BT_INTERESTED_TRIP' | translate
      }}</ion-button>
          </div>
    <ng-container *ngIf="new_interested.length + interested.length > 0">
      <!-- <div *ngIf="new_interested.length > 0" class="interested">
        <span class="title">{{ 'TRIP.NEW_INTERESTED' | translate }}</span>
        <div class="avatars" fxLayout="row wrap">
          <ion-avatar *ngFor="let item of new_interested" class="interested" >
            <img [src]="userService?.getMemberThumb(item)" (click)="clickInterested(item)" />
          </ion-avatar>
          
        </div>
      </div> -->

      <div *ngIf="interested.length > 0" class="interested">
        <span class="title">{{ 'TRIP.INTERESTED' | translate }}</span>

        <div class="avatars" fxLayout="row wrap">
          <ion-avatar *ngFor="let item of interested" class="interested" [class.new]="trip?.interested[item.id]?.new">
            <img  [src]="userService?.getMemberThumb(item)" (click)="clickInterested(item)" />
          </ion-avatar>

          
        </div>

      </div>
    </ng-container>

    <div class="div-promote" *ngIf="mode == 'mine' && trip.published">
      <ion-button *ngIf="!trip.isPromoted()" color="primary" size="small" (click)="promoteTrip()">{{ 'TRIP.BT_PROMOTE' | translate }}</ion-button>

      <span  *ngIf="trip.isPromoted()" class="promoted">{{ 'TRIP.PROMOTED_TRIP' | translate : {duration: trip?.promote_to  | amFromUtc | amLocale:lang | amTimeAgo:true} }}</span>
            

    </div>

  </div>
</div>
