import { NoAuthGuard } from './guards/noauth.guard';
import { RegisterWithEmailPage } from './pages/registeremail/registeremail.page';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'resetpassword',
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./pages/resetpassword/resetpassword.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'registeremail',
    canActivate: [NoAuthGuard],
    loadChildren: () => import('./pages/registeremail/registeremail.module').then(m => m.RegisterWithEmailPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'registernext',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/registernext/registernext.module').then(m => m.RegisterNextPageModule),
    data: {nomenu:true}
  },
  {
    path: 'validateaccount',
    loadChildren: () => import('./pages/validateaccount/validateaccount.module').then(m => m.ValidateAccountPageModule),
    data: {nomenu:true}
  },
  {
    path: '',
    canActivate: [AuthGuard],
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
    data: {test:true}
  },
  {
    path: 'search',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
  },
  {
    path: 'visitors',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/visitors/visitors.module').then(m => m.VisitorsPageModule)
  },
  {
    path: 'favorites',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/favorites/favorites.module').then(m => m.FavoritesPageModule)
  },
  {
    path: 'member',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/member/member.module').then(m => m.MemberPageModule)
  },
  {
    path: 'chat',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule)
  },
  {
    path: 'chats',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/chats/chats.module').then(m => m.ChatsPageModule)
  },
  {
    path: 'trips',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/trips/trips.module').then(m => m.TripsPageModule)
  },
  {
    path: 'mytrips',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/mytrips/mytrips.module').then(m => m.MytripsPageModule)
  },
  {
    path: 'photos',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/photos/photos.module').then(m => m.PhotosPageModule)
  },
  {
    path: 'votes',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/votes/votes.module').then(m => m.VotesPageModule)
  },
  {
    path: 'shares',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/shares/shares.module').then(m => m.SharesPageRoutingModule)
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
  },
  // {
  //   path: '**',
  //   redirectTo: '/dashboard'
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
