<div *ngIf="showSplash" class="splash"></div>
<ion-app>
  <div class="no-network-mask" *ngIf="!userService?.networkUp"></div>
  <ion-split-pane [class.minimized]="!deviceModeService.showVerticalMenu" contentId="main" when="lg"
    class="{{deviceModeService.getDevice()}}">
    <ion-menu contentId="main">
      <div class="sidemenu">
        <ion-header>
          <ion-col col-12>
            <div *ngIf="userService?.user?.avatar" class="twb-avatar-cropper" (click)="goTo('/settings')">
              <div class="twb-avatar-rounded thumb128 hand"
                [ngStyle]="{ 'background-image': 'url(' + userService?.getMemberThumb() + ')' }">
                <ion-icon routerLink="/settings" name="settings" class="settings-icon"></ion-icon>
              </div>
            </div>
            <div class="display-name">
              <span>{{ userService?.profile?.displayName }}</span>

              <member-stars class="hide-empty-stars small-stars" [showLink]="'/votes'" [member]="userService?.profile">
              </member-stars>
            </div>
          </ion-col>
        </ion-header>

        <ion-content class="sidemenu-list">
          <ion-list class="{{deviceModeService.getDevice()}}">
            <ion-menu-toggle auto-hide="false" *ngFor="let p of appPages">
              <div [routerDirection]="'root'" [routerLink]="[p.url]" class="new-menu-item hand transparent" routerLinkActive="active-item">
                <fa-icon *ngIf="p.fa" class="menu-icon-fa" slot="start" [icon]="p.fa"></fa-icon>
                <span>{{ p.title | translate }}</span>
                <ion-badge *ngIf="p.badge && userService.nbEvents[p.badge] > 0" color="secondary">
                  {{ userService.nbEvents[p.badge] }}</ion-badge>
              </div>
              <!-- <ion-item [routerDirection]="'root'" [routerLink]="[p.url]" class="transparent" routerLinkActive="active-item">
                <fa-icon *ngIf="p.fa" class="menu-icon-fa" slot="start" [icon]="p.fa"></fa-icon>
                <ion-label>{{ p.title | translate }}</ion-label>
                <ion-badge *ngIf="p.badge && userService.nbEvents[p.badge] > 0" color="secondary">
                  {{ userService.nbEvents[p.badge] }}</ion-badge>
              </ion-item> -->
            </ion-menu-toggle>
          </ion-list>
        </ion-content>
      </div>
    </ion-menu>

    <ion-router-outlet id="main" [swipeGesture]="false" animated="false"></ion-router-outlet>
  </ion-split-pane>
</ion-app>