import { Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Camera, CameraDirection, CameraResultType, CameraSource, ImageOptions, Photo } from "@capacitor/camera";
import { Device, DeviceInfo } from "@capacitor/device";
import { Filesystem, ReadFileResult } from "@capacitor/filesystem";
import { ActionSheetController, AlertController, LoadingController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class PhotoService {
  private loadingPhotoController: any;
  imagePreview: any;
  uploadedImage: File;
  last_photo: Photo = null;
  platform = null;
  renderer: Renderer2 = null;
  inputfile = null;
  last_file: File = null;

  constructor(
    public actionSheetController: ActionSheetController,
    private loadingController: LoadingController,
    private translate: TranslateService,
    public alertController: AlertController,
    private sanitizer: DomSanitizer,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);

    // this.imagePreview = '/assets/img/user-profile-img.jpg'
    Device.getInfo().then((info: DeviceInfo) => {
      this.platform = info.platform;
    });
  }

  clear() {
    this.uploadedImage = null;
    this.imagePreview = null;
  }

  getBackgroundImagePreview(img = null) {
    if (img) {
      return this.sanitizer.bypassSecurityTrustStyle(`url('${img}')`);
    }
    return this.imagePreview ? this.sanitizer.bypassSecurityTrustStyle(`url('${this.imagePreview}')`) : null;
  }

  photo(options = null) {
    if (this.platform != "web") {
      return this.selectPhotoSource(options).then((source: CameraSource) => {
        this.clear();

        return this.takePhoto(source);
      });
    } else {
      return new Promise((resolve, reject) => {
        if (!this.inputfile) {
          let input = this.renderer.createElement("input");
          this.renderer.setAttribute(input, "type", "file");
          this.renderer.addClass(input, "hidden");
          this.renderer.appendChild(document.body, input);
          this.renderer.listen(input, "change", (event) => {
            this.last_file = event.target.files[0];

            if (this.last_file) {
              let fileReader = new FileReader();
              fileReader.onloadend = (e) => {
                this.imagePreview = fileReader.result;

                resolve(true);
              };

              fileReader.readAsDataURL(this.last_file);
            } else {
              this.last_file = null;
              reject();
            }
          });
          this.inputfile = input;
        }

        setTimeout(() => {
          this.inputfile.click();
        }, 100);
      });
    }
  }

  selectPhotoSource(options = null): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const buttons = [];
      if (!options || options.mode == "camera") {
        buttons.push({
          text: this.translate.instant("PHOTOS.FROM_CAMERA"),
          role: "destructive",
          icon: "camera",
          handler: () => {
            resolve(CameraSource.Camera);
          },
        });
      }
      if (!options || options.mode == "gallery") {
        buttons.push({
          text: this.translate.instant("PHOTOS.FROM_GALLERY"),
          icon: "images",
          handler: () => {
            resolve(CameraSource.Photos);
          },
        });
      }

      if (!options) {
        options = {};
      }
      buttons.push({
        text: this.translate.instant("BUTTONS.BT_CANCEL"),
        icon: "close",
        role: "cancel",
        handler: () => {
          reject("cancel");
        },
      });
      const actionSheet = await this.actionSheetController.create({
        header: options.title || this.translate.instant("PHOTOS.NEW_PHOTO_MENU"),
        buttons: buttons,
      });
      await actionSheet.present();
    });
  }

  public takePhoto(
    source = CameraSource.Prompt,
    options: ImageOptions = {
      quality: 60,
      allowEditing: false,
      /**
       * How the data should be returned. Currently, only 'Base64', 'DataUrl' or 'Uri' is supported
       */
      resultType: CameraResultType.Uri,
      saveToGallery: false,
      width: 1024,
      //height?: number;
      correctOrientation: true,
      source: CameraSource.Prompt,
      direction: CameraDirection.Front,
      presentationStyle: "fullscreen",
    }
  ) {
    options.source = source;
    return new Promise((resolve, reject) => {
      Camera.getPhoto(options).then((photo: Photo) => {
        this.last_photo = photo;
        this.imagePreview = photo.webPath;
        resolve(true);
      });
    });
  }

  async getPhotoAsBlob() {
    if (this.platform == "web") {
      return new Promise((resolve, reject) => {
        if (this.last_file) {
          let fileReader = new FileReader();
          fileReader.onloadend = (e) => {
            let s = fileReader.result as string;
            let base64String = s.split(",").pop();
            let blob: Blob = this.b64toBlob(base64String);
            resolve(blob);
          };

          fileReader.readAsDataURL(this.last_file);
        } else {
          this.last_file = null;
          reject();
        }
      });
    } else {
      return new Promise((resolve, reject) => {
        Filesystem.readFile({
          path: this.last_photo.path,
        })
          .then((contents: ReadFileResult) => {
            let blob: Blob = this.b64toBlob(contents.data);
            resolve(blob);
          })
          .catch((err) => {
            console.log("Error getPhotoAsBlob", err);
            resolve(null);
          });
      });
    }
  }

  b64toBlob(b64Data, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  async loadingPhoto(state: boolean, msg = null) {
    if (state) {
      this.loadingPhotoController = await this.loadingController.create({
        message: msg,
        // duration: 2000,
        spinner: "dots",
      });

      await this.loadingPhotoController.present();
    } else {
      this.loadingPhotoController.dismiss();
    }
  }
}
