import { ToastService } from './../../services/toast.service';
import { environment } from './../../../environments/environment';
import { Profile } from './../../models/profile';
import { UserService } from '../../services/user.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TripsService } from '../../services/trips.service';
import { ListsService } from '../../services/lists.service';
import { Trip } from './../../models/trip';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { NavController, AlertController, IonicSafeString } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'trip-card',
  templateUrl: './trip-card.component.html',
  styleUrls: ['./trip-card.component.scss'],
})
export class TripCardComponent implements OnInit {
  @Input() trip: Trip;
  @Input() mode = 'list';
  @Input() disabled = false;
  @Input() shortDescription = false;
  @Input() showBtView = true;
  @Input() showBtInterested = false;
  @Input() showDetails = false;
  @Output() onViewed = new EventEmitter<any>();
  @Output() onMenuClicked = new EventEmitter<any>();

  public lang = 'en';

  isFavorite = false;
  isOnline = false;
  country = null;
  with = null;
  costs = null;
  purpose = [];
  member = null;
  disable_interest = false;
  interested: Profile[] = [];
  new_interested: Profile[] = [];

  constructor(
    public tripsService: TripsService,
    public userService: UserService,
    public listsService: ListsService,
    public router: Router,
    private nav: NavController,
    private translate: TranslateService,
    private alertController: AlertController,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.lang = this.translate.currentLang;

    if (!this.trip.photo_url) {
      this.trip.photo_url = '/assets/img/trips/trip0.jpg';
    }
    let t = this.trip.destination.split(',');
    this.trip.destination = _.uniqBy(t, (o) => {
      return (o + '').trim();
    }).join(',');

    this.with = this.listsService.getValue(this.trip['with'], 'tripWith');
    this.costs = this.listsService.getValue(this.trip['costs'], 'tripCosts');
    // this.isOnline = this.trip.member.isOnline();
    for (let i in this.trip['purpose']) {
      this.purpose.push(this.listsService.getValue(this.trip['purpose'][i], 'tripPurpose'));
    }
    this.disable_interest = this.disabled || this.userService.user.id == this.trip.uid || this.trip.with != this.userService.profile.gender;

    if (this.mode == 'mine' && this.trip.interested) {
      for (let uid in this.trip.interested) {
        firebase
          .database()
          .ref(`members/${uid}`)
          .once('value')
          .then((snap) => {
            if (snap.exists()) {
              let member = new Profile(snap.val());
              if (!member.is_banned && !member.is_deleted && !member.is_hidden) {
                this.interested.push(member);

                if (this.trip.interested[member.id] && this.trip.interested[member.id].new) {
                  this.new_interested.push(member);
                }
              }
            }
          });
      }
    }
  }

  iAmInterested() {
    if (this.disabled) {
      return false;
    }
    if (this.userService.isCompleteOrPremium()) {
      this.trip.interested[this.userService.user.id] = true;

      if (!this.userService.user.is_banned) {
        firebase
          .database()
          .ref(`trips/${this.trip.id}/interested/${this.userService.user.id}`)
          .set({ new: true, date: moment().utc().format('YYYY-MM-DD HH:mm:ss') });
        this.userService.addEvent(this.trip.uid, 'tripinterest', 'new');
        this.userService.track('interested', 'Trips');

        // this.router.navigate([`member/${this.trip.member.uri}`]);
      }
    }
  }

  click(src = 'bt') {
    if (this.disabled) {
      return false;
    }
    if (src == 'img' && this.mode != 'list') {
      return false;
    }
    this.router.navigate([`member`, this.trip.member.uri, 'trips', this.trip.id]);
  }

  clickInterested(member: Profile) {
    if (this.disabled) {
      return false;
    }
    if (this.trip.interested[member.id].new) {
      this.trip.interested[member.id].new = false;
      firebase.database().ref(`trips/${this.trip.id}/interested/${member.id}/new`).set(false);
    }
    this.router.navigate([`member`, member.uri]);
  }

  menu() {
    if (this.disabled) {
      return false;
    }
    this.onMenuClicked.emit(this.trip);
  }

  async promoteTrip() {
    let price = environment.miles.trip;

    if (this.trip.isPromoted()) {
      return false;
    }

    let bt_ok = this.translate.instant('TRIP.PROMOTE.BT_PROMOTE_PRICE', { price: price / 100 });

    if (this.userService.getCredits() < price) {
      bt_ok = this.translate.instant('BUTTONS.BT_BUY_MILES');
    }
    const alert = await this.alertController.create({
      header: this.translate.instant('TRIP.PROMOTE.TITLE'),
      message: new IonicSafeString(this.translate.instant('TRIP.PROMOTE.MSG', { price: price / 100 })),
      cssClass: 'promote-dialog',
      buttons: [
        {
          text: this.translate.instant('BUTTONS.BT_CANCEL'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {},
        },
        {
          text: bt_ok,
          handler: () => {
            if (this.userService.getCredits() < price) {
              this.nav.navigateForward('/settings/miles');
            } else {
              let promote_to = moment().utc().add(1, 'week').format('YYYY-MM-DDTHH:mm:ss');
              this.trip.promote_to = promote_to;
              firebase
                .database()
                .ref(`trips/${this.trip.id}/promote_to`)
                .set(promote_to)
                .then((res) => {
                  this.userService.useCredits('trip_promo');
                  this.userService.log('my-trips promote');
                });
            }
          },
        },
      ],
    });

    await alert.present();
  }
}
