import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Device } from "@capacitor/device";
import { Keyboard, KeyboardInfo } from "@capacitor/keyboard";
import { ConnectionStatus, Network } from "@capacitor/network";
import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Config, LoadingController, MenuController, NavController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import firebase from "firebase/compat/app";
import * as moment from "moment";
import { Observable } from "rxjs";
import { AppStoreService } from "./services/appstore.service";
import { AuthService } from "./services/auth.service";
import { CommonService } from "./services/common.service";
import { DeviceModeService } from "./services/devicemode.service";
import { GeolocationService } from "./services/geolocation.service";
import { NotificationService } from "./services/notifications.service";
import { ToastService } from "./services/toast.service";
import { UserService } from "./services/user.service";


@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public appPages;
  public showSplash = true;
  public menuEnabled = true;
  public isNetworkActive = null;
  public network_loader = null;
  private _paused_ts = null;

  constructor(
    private translate: TranslateService,
    private loadingController: LoadingController,
    private platform: Platform,
    public auth: AuthService,
    public userService: UserService,
    private config: Config,
    private router: Router,
    private menuCtrl: MenuController,
    public toast: ToastService,
    public geolocation: GeolocationService,
    private route: ActivatedRoute,
    private notifications: NotificationService, // do not remove
    private store: AppStoreService,
    private common: CommonService,
    private nav: NavController,
    public deviceModeService: DeviceModeService,
  ) {
    this.menuCtrl.enable(false);

    this.userService.isProfileReady.subscribe((ready) => {
      this.menu();
      this.menuCtrl.enable(ready);
    });

    this.initializeApp();
  }

  async ngOnInit() {
    //   this.network.onDisconnect().subscribe(() => {
    //     console.log('network was disconnected :-(');
    //     this.toast.show(this.translate.instant('ERRORS.NO_NETWORK', { color: 'danger' }));
    //   });
    // this.route.url.subscribe((value) => console.log('route', value));
  }

  async initializeApp() {
    this.initTranslate();

    this.platform.ready().then(() => {
      Device.getInfo().then((info) => {
        if (info.platform == "ios" || info.platform == "android") {
          this.checkNetwork();

          this.initKeyboard();

          setTimeout(() => {
            SplashScreen.hide({
              fadeOutDuration: 0,
            }).then(() => {
              StatusBar.setStyle({ style: Style.Dark });

            });
          }, 50);

          // setTimeout(() => {

          //   this.initAppRate();
          // }, 30000);

          this.platform.pause.subscribe((res) => {
            this.onAppPaused(res);
          });

          this.platform.resume.subscribe((res) => {
            this.onAppResumed(res);
          });
        }

        this.store.init();
      });
    });
  }

  initKeyboard() {
    Keyboard.addListener("keyboardDidShow", (info: KeyboardInfo) => {
      this.common.keyboardChanged.next(true);
    });

    Keyboard.addListener("keyboardDidHide", () => {
      this.common.keyboardChanged.next(false);
    });
  }

  checkNetwork() {
    this.networkStatus().subscribe((network) => {
      this.isNetworkActive = network.connected;
      if (!this.isNetworkActive) {
        if (this.network_loader) {
          this.network_loader.present();
        } else {
          setTimeout(()=>{
            let msg = this.translate.instant("APP.NO_NETWORK");
            this.loadingController
              .create({
                id: "network",
                message: msg,
              })
              .then((loader) => {
                loader.present();
                this.network_loader = loader;
              });
          },1000)
        }
      } else if (this.network_loader) {
        try {
          this.network_loader.dismiss({ id: "network" });
        } catch (e) {
          console.log("No previous loader to close");
        }
        this.network_loader = null;
      }
    });
  }

  networkStatus() {
    return new Observable<ConnectionStatus>((observer) => {
      Network.getStatus().then((network: ConnectionStatus) => {
        observer.next(network);
      });

      Network.addListener("networkStatusChange", (network: ConnectionStatus) => {
        observer.next(network);
      });
    });
  }

  // initAppRate() {
  //   // or, override the whole preferences object
  //   this.appRate.preferences = {
  //     usesUntilPrompt: 5,
  //     displayAppName: "TripsWithBenefits",
  //     storeAppURL: {
  //       ios: "1398306021",
  //       android: "market://details?id=com.trips.withbenefits",
  //     },
  //     customLocale: {
  //       title: this.translate.instant("APPRATE.TITLE"),
  //       message: new IonicSafeString(this.translate.instant("APPRATE.MESSAGE"),
  //       cancelButtonLabel: this.translate.instant("APPRATE.BT_CANCEL"),
  //       laterButtonLabel: this.translate.instant("APPRATE.BT_LATER"),
  //       rateButtonLabel: this.translate.instant("APPRATE.BT_RATE"),
  //       yesButtonLabel: this.translate.instant("APPRATE.BT_YES"),
  //       noButtonLabel: this.translate.instant("APPRATE.BT_NO"),
  //       appRatePromptTitle: this.translate.instant("APPRATE.PROMPT"),
  //       feedbackPromptTitle: this.translate.instant("APPRATE.FEEDBACK"),
  //     },
  //     openUrl: this.appRate.preferences.openUrl,
  //     callbacks: {
  //       // handleNegativeFeedback: function(){
  //       //   console.log("handleNegativeFeedback -> " );

  //       // },
  //       onRateDialogShow: function (callback) {
  //         callback(1); // cause immediate click on 'Rate Now' button
  //       },
  //       onButtonClicked: function (buttonIndex) {
  //         console.log("onButtonClicked -> " + buttonIndex);
  //       },
  //     },
  //   };

  //   this.appRate.promptForRating(false);
  // }

  async initTranslate() {
    const browserLang = this.translate.getBrowserLang();
    //const browserLang = null;

    let langs = ["fr", "en", "ro", "it", "de"];
    // if (!environment.production) {
    //   langs = ['fr', 'en', 'ro', 'it', 'de'];

    // }
    this.translate.addLangs(langs);
    let currentLang = await this.auth.getSavedOrDefaultLang();
    if (!currentLang) {
      currentLang = browserLang;
    }
    this.translate.setDefaultLang(currentLang);

    // let lang = this.translate.getBrowserLang();
    let lang = currentLang;
    if (langs.indexOf(lang) == -1) {
      lang = "en";
    }

    this.translate.use(lang).subscribe(() => {
      this.menu();
    });

    this.translate.onLangChange.subscribe(() => {
      this.menu();
    });

    // this.translate.get(["APP.BACK_BUTTON_TEXT"]).subscribe((values) => {
    //   this.config.set("backButtonText", values.BACK_BUTTON_TEXT);
    // });
  }

  goVote() {
    this.router.navigate(["votes"]);
  }

  goTo(url) {
    this.menuCtrl.close();
    this.nav.navigateForward(url);
  }

  menu() {
    this.appPages = [
      {
        title: this.translate.instant("APP.MENU.DASHBOARD"),
        xsrc: "/assets/icon/dashboard.svg",
        fa: ["fal", "paper-plane"],
        url: "/dashboard",
      },
      {
        title: this.translate.instant("APP.MENU.SEARCH"),
        xsrc: "/assets/icon/searchmembers.svg",
        fa: ["fal", "search"],
        url: "/search",
      },
      {
        title: this.translate.instant("APP.MENU.SEARCH_TRIPS"),
        xsrc: "airplane",
        fa: ["fal", "plane"],
        url: "/trips",
      },
      {
        title: this.translate.instant("APP.MENU.MESSAGES"),
        xsrc: "/assets/icon/chats.svg",
        fa: ["fal", "comments"],
        url: "/chats",
        badge: "message",
      },
      {
        title: this.translate.instant("APP.MENU.VISITORS"),
        xsrc: "/assets/icon/visitors.svg",
        fa: ["fal", "user-friends"],
        url: "/visitors",
        badge: "visit",
      },
      {
        title: this.translate.instant("APP.MENU.FAVORITES"),
        xsrc: "/assets/icon/favorites.svg",
        fa: ["fal", "heart"],
        url: "/favorites",
        badge: "favorite",
      },
      {
        title: this.translate.instant("APP.MENU.MY.TRIPS"),
        xsrc: "/assets/icon/mytrips.svg",
        fa: ["fal", "tree-palm"],
        url: "/mytrips",
        badge: "tripinterest",
      },
      {
        title: this.translate.instant("APP.MENU.MY.PHOTOS"),
        xsrc: "/assets/icon/photos.svg",
        fa: ["fal", "image"],
        url: "/photos",
      },
      {
        title: this.translate.instant("APP.MENU.MY.SHARES"),
        xsrc: "/assets/icon/photoshares.svg",
        fa: ["fal", "share-alt"],
        url: "/shares",
        badge: "photos_requests",
      },
    ];
    if (this.userService.user && this.userService.user.gender == "male") {
      this.appPages.push({
        title: this.translate.instant("APP.MENU.MEMBERSHIP"),
        fa: ["fal", "medal"],
        url: "/settings/membership",
      });
    }
  }

  onAppPaused(res) {
    this._paused_ts = moment();
  }

  onAppResumed(res) {
    if (this.auth.isUserLogged) {
      if (this._paused_ts) {
        const duration = moment.duration(moment().diff(this._paused_ts));
        const minutes = duration.asMinutes();

        if (minutes >= 1) {
          firebase.database().goOnline();
        }
      }
    }
  }
}
