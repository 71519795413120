import { StorageService } from "./services/storage.service";
import { AudioService } from "./services/audio.service";
import { AppStoreService } from "./services/appstore.service";
import { AnalyticsService } from "./services/analytics.service";
import { NotificationService } from "./services/notifications.service";
import { NativeGeocoder } from "@awesome-cordova-plugins/native-geocoder/ngx";
import { CommonService } from "./services/common.service";
import { GeolocationService } from "./services/geolocation.service";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthService } from "./services/auth.service";
import { ListsService } from "./services/lists.service";
import { MembersService } from "./services/members.service";
import { TripsService } from "./services/trips.service";
import { UserService } from "./services/user.service";
import { SharedModule } from "./shared/shared.module";
import { InAppPurchase2 } from "@awesome-cordova-plugins/in-app-purchase-2/ngx";
import { PhotoService } from "./services/photo.service";
import { DeviceModeService } from "./services/devicemode.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { AppRateService } from "./services/app-rate.service";
import { register } from "swiper/element/bundle";

register();

export function createTranslateLoader(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: "./assets/i18n/", suffix: ".json" },
    { prefix: "./assets/i18n/", suffix: ".static.json" },
  ]);
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      scrollPadding: false,
      scrollAssist: true,
      backButtonText: "",
      innerHTMLTemplatesEnabled: true
    }),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    IonicStorageModule.forRoot({
      name: "__twbdb",
      driverOrder: ["indexeddb", "sqlite", "websql"],
    }),
    ServiceWorkerModule.register("combined-sw.js", { enabled: environment.pwa === true }),
    ServiceWorkerModule.register("ngsw-worker.js", { enabled: environment.production == true && environment.pwa === true }),
  ],
  providers: [
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: (auth: AuthService) =>
        function () {
          return auth.getLocalAuth();
        },
      deps: [AuthService],
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    CommonService,
    UserService,
    Geolocation,
    NativeGeocoder,
    ListsService,
    MembersService,
    TripsService,
    NotificationService,
    GeolocationService,
    PhotoService,
    AnalyticsService,
    AppStoreService,
    InAppPurchase2,
    AudioService,
    StorageService,
    DeviceModeService,
    AppRateService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  bootstrap: [AppComponent],
})
export class AppModule {}
