import * as moment from 'moment';
import { Photo } from './photo';

export class Profile {
  public id: string = null;
  public cid: string = null;
  public isComplete: boolean = false;
  public displayName: string = '';
  public profilePhoto: Photo = new Photo();
  // public headerPhoto : UserPhoto = new UserPhoto();
  public photos: Photo[] = [];
  public creationDate: string = null;
  public completeDate: string = null;
  public lastUpdateDate: string = null;
  public gender: string = null;
  public birthDate: string = null;
  public lastlogDate: string = null;
  public country: string = null;
  public location: string = null;
  public city: string = null;
  public longitude: number = null;
  public latitude: number = null;
  public place_id: string = null;
  public eyes: string = null;
  public height: number = null;
  public bodyType: string = null;
  public hairColor: string = null;
  public education: string = null;
  public smoking: string = null;
  public alcohol: string = null;
  public sexuality: string = null;
  public language = null;
  public nationality = null;
  public lookingFor: string[] = null;
  public wantToVisit_ids: string[] = null;
  public wantToVisit: Object = null;
  public aboutMe: string = null;
  public aboutMe_temp: string = null;
  public aboutMe_status: string = null;
  public online: boolean = null;
  public lastSeen: string = null;
  public lastSeenReal: string = null;
  public confidentiality = { register: false, login: false, online: false, browse: false }
  public banner: string = 'assets/img/banners/1.jpg';
  public has_photos: boolean = false;
  public is_banned: boolean = false;
  public verified: boolean = false;
  public uri: string = null;
  public is_deleted: boolean = false;
  public is_hidden: boolean = false;
  public is_disabled: boolean = false;
  public votes = { sum: 0, n: 0, avg: 0, trips: 0, description: 0, avatar: 0, photos: 0, profile: 0, reply: 0, msg: 0, verified: 1 };
  public score = 0;
  public rank = 0;
  public promote_to = null;
  public address: any = null;

  public deviceInfos = { HardwareVendor: null, HardwareModel: null, DeviceType: null, BrowserName: null, BrowserVersion: null, IsMobile: null, IsSmartPhone: null, PlatformName: null, PlatformVersion: null };
  public subscription_expire = null;

  public videocalls = {
    enabled: true,
    everybody: false
  };

  constructor(id?) {
    if (id) {
      if (typeof id == 'object') {
        this.import(id);
      } else {
        this.id = id;
      }
    }
  }

  import(data) {
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }

  exists() {
    return this.id !== null;
  }

  isProfileComplete() {
    return this.isComplete;
  }
  

  isOnline() {

    const now = moment().utc();
    const lastSeen = this.lastSeen 
    if (this.lastSeen) {
      return moment.utc(lastSeen).add(1,'day').isSameOrAfter(now);
    }
    return !this.confidentiality.online && this.online;
  }

  getAge() {
    return moment().diff(this.birthDate, 'years');
  }

  isPromoted() {
    if (this.promote_to) {
      return moment.utc().format('YYYY-MM-DD HH:mm')<=moment.utc(this.promote_to).format('YYYY-MM-DD HH:mm');


    }
    return false;
  }

  getApprovedPhotos(privat = false) {
    const ret = [];
    if (this.photos) {
      this.photos.map(photo => {
        if (photo.status == 'accepted' && photo.private == privat) {
          ret.push(photo);
        }
      });
    }
    return ret;
  }

  getProfilePhotoUrl(thumb = true, uid = null) {
    // if (this.is_banned) {
    //   return '/assets/img/avatars/' + this.gender + 'banned.png';
    // }
    // if (this.is_disabled) {
    //     return '/assets/img/avatars/'+this.gender+'.png'
    // }
    if (this.profilePhoto && thumb && this.profilePhoto.thumbnail) {
      let thumbnail=  this.profilePhoto.thumbnail ? this.profilePhoto.thumbnail : this.profilePhoto.url;
      return this.isPremium() && this.profilePhoto.private && uid != this.id
        ? '/assets/img/avatars/' + this.gender + '_anonymous.png'
        : thumbnail;
    }
    if (this.profilePhoto) {
      return (this.isPremium() && (this.profilePhoto.private || !this.isComplete )) && uid != this.id
        ? '/assets/img/avatars/' + this.gender + '_anonymous.png'
        : this.profilePhoto.url;
    }
    return '/assets/img/avatars/' + this.gender + '.png';
  }

  getBadges() {
    let ret = '';
    if (this.isOnline()) {
      ret += '<div class="circle circle-lg circle-success" tooltip="online"></div>';
    }
    return ret;
  }

  isPremium() {
    if (this.subscription_expire) {
      return moment.utc(this.subscription_expire).isSameOrAfter(moment.utc())

    }
    return false;
  }
}
