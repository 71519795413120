import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { SignInWithApple } from "@capacitor-community/apple-sign-in";
import { Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import firebase from "firebase/compat/app";
import 'firebase/compat/database';
import 'firebase/compat/auth';
import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { environment } from "./../../environments/environment";
import { User } from "./../models/user";
import { DeviceModeService } from "./devicemode.service";
import { StorageService } from "./storage.service";
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

const FACEBOOK_PERMISSIONS = ["email", "user_birthday", "user_gender"];

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private user: firebase.User;
  private isLogged = false;
  public error = null;
  public getLanguage: string = null;
  private authState: any = null;
  public isAuthReady = new BehaviorSubject(false);
  private connected_ref = null;

  constructor(
    public afAuth: AngularFireAuth,
    private storage: StorageService,
    private translate: TranslateService,
    public platform: Platform,
    private http: HttpClient,
    private deviceModeService: DeviceModeService
  ) {
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        // User is signed in.
        this.user = user;
        this.isLogged = true;
        this.updateOnlineStatus();
        this.isAuthReady.next(true);
        // store user
        this.storage.set("afUser", JSON.stringify(user));
        //        this.isPreloadAuthReady.next(false);
      } else {
        // User is signed out.
        this.isLogged = false;
        this.user = null;

        this.storage.remove("afUser");
        this.isAuthReady.next(false);
        //        this.isPreloadAuthReady.next(false);
      }
    });
  }

  updateOnlineStatus() {
    this.connected_ref = firebase.database().ref(".info/connected");
    this.connected_ref.on("value", (snap) => {
      if (snap.exists()) {
        let connected = snap.val();
        // if (connected) {
        //   firebase.database().ref(`members/${this.user.uid}/online`).set(true);
        // }
        const update = {
          online: connected,
          lastSeen: moment().utc().format("YYYY-MM-DDTHH:mm:ss"),
          lastSeenReal: moment().utc().format("YYYY-MM-DDTHH:mm:ss"),
        };
        firebase.database().ref(`members/${this.user.uid}`).update(update);
      }
    });
    firebase
      .database()
      .ref()
      .child(`members/${this.user.uid}`)
      .onDisconnect()
      .update({
        online: false,
        lastSeen: moment().utc().format("YYYY-MM-DDTHH:mm:ss"),
        lastSeenReal: moment().utc().format("YYYY-MM-DDTHH:mm:ss"),
      });
  }

  async initLanguage() {
    let language = "en";

    if (await this.storage.get("language")) {
      language = await this.storage.get("language");
    }

    this.translate.setDefaultLang(language);
    this.getLanguage = this.translate.getDefaultLang();
    firebase.auth().languageCode = this.translate.getDefaultLang();
    //this.afAuth.languageCode = new Promise<firebase.auth.Auth>(() => this.translate.getDefaultLang());
  }

  changeLanguage(language) {
    this.storage.set("language", language);
    this.translate.use(language);
    this.getLanguage = language;
    if (this.user && this.user.uid) {
      firebase.auth().languageCode = this.translate.currentLang;
      //this.afAuth.languageCode = new Promise(() => this.translate.currentLang);
      firebase.database().ref(`users/${this.user.uid}/language`).set(language);
    }
  }

  async getSavedOrDefaultLang() {
    const lang = await this.storage.get("language");
    if (lang) {
      return lang;
    }

    return null;
  }

  signInWithEmail(credentials) {
    return this.afAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
      return this.afAuth.signInWithEmailAndPassword(credentials.email, credentials.password);
    });
  }

  isUserLogged() {
    return this.isLogged;
  }

  logout(reload = true) {
    this.isLogged = false;
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.storage.remove("afUser");
        if (reload) {
          location.href = "/";
        }
      });
  }

  setError(error) {
    this.error = error;
  }

  getAuthUser() {
    return this.user;
  }

  getLocalAuth() {
    return new Promise((resolve, reject) => {
      this.storage
        .get("afUser")
        .then((user) => {
          if (user) {
            this.user = JSON.parse(user);
            this.isLogged = true;
            //this.isPreloadAuthReady.next(true);
          } else {
            this.user = null;
            this.isLogged = false;
          }
          resolve(true);
        })
        .catch((res) => {
          console.log("Error", res);
          resolve(true);
        });
    });
  }

  saveUser(provider, authuser: firebase.auth.UserCredential) {
    return new Promise((resolve, reject) => {
      let user = authuser.user;
      const infos = authuser.additionalUserInfo;
      if (infos && infos.isNewUser) {
        let u = new User();
        u.id = user.uid;
        u.platform = this.platform.is("ios") ? "ios" : "android";
        u.authProvider = provider;
        u.email = user.email;
        u.language = this.translate.currentLang;
        u.emailVerified = provider != "email" || user.emailVerified;
        u.creationDate = moment().utc().toISOString(); // prettier-ignore
        if (infos.profile) {
          u.firstname = infos.profile["given_name"] || null;
          u.lastname = infos.profile["family_name"] || null;
          u.birthDate = infos.profile["birthdate"] || null;
          u.gender = infos.profile["gender"] || null;
        };
        firebase
          .database()
          .ref(`users/${user.uid}`)
          .set(u)
          .then(() => {
            resolve(user);
          });
      } else {
        firebase
          .database()
          .ref(`users/${user.uid}`)
          .once("value")
          .then((snap) => {
            if (snap.exists()) {
              resolve(new User(snap.val()));
            } else {
              reject("user_not_exists");
            }
          });
      }
    });
  }

  /**
   * Register the user
   *
   * @param {string} [provider="google"]
   * @param {*} [credentials=null]
   * @memberof AuthService
   */
  register(provider = "email", credentials = null) {
    return new Promise((resolve, reject) => {
      switch (provider) {
        case "email":
          this.afAuth
            .createUserWithEmailAndPassword(credentials.email, credentials.password)
            .then((authuser: firebase.auth.UserCredential) => {
              this.saveUser(provider, authuser).then((user) => {
                resolve(authuser);
              });
            })
            .catch((reason) => {
              console.log("ERROR REGISTER", reason);

              reject(reason);
            });
          break;

        case "apple":
          return this.login(provider, credentials);
          break;
        case "google":
          return this.login(provider, credentials);
          break;
      }
    });
  }

  login(provider = "google", credentials = null) {
    return new Promise((resolve, reject) => {
      switch (provider) {
        case "email":
          this.afAuth
            .signInWithEmailAndPassword(credentials.email, credentials.password)
            .then((result) => {
              resolve(result);
            })
            .catch((reason) => {
              reject(reason);
            });
          break;

        case "google":
          if (this.platform.is("hybrid") && (this.platform.is("android") || this.platform.is("ios"))) {
            GoogleAuth.signIn()
              .then((googleUser) => {
                const google_credential = firebase.auth.GoogleAuthProvider.credential(googleUser.authentication.idToken);

                this.afAuth
                  .signInWithCredential(google_credential)
                  .then((authuser) => {
                    let user = authuser.user;

                    this.saveUser(provider, authuser)
                      .then((user) => {
                        resolve(authuser);
                      })
                      .catch((reason) => {
                        console.log("ERROR GOOGLE", reason);
                        reject(reason);
                      });
                  })
                  .catch((reason) => {
                    reject(reason);
                  });
              })
              .catch((reason) => {
                reject(reason);
              });
          } else {
            const provider = new firebase.auth.GoogleAuthProvider();
            //provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
            firebase
              .auth()
              .signInWithPopup(provider)
              .then((authuser: firebase.auth.UserCredential) => {
                // The signed-in user info.

                let user = authuser.user;

                this.saveUser(provider, authuser)
                  .then((user) => {
                    resolve(authuser);
                  })
                  .catch((reason) => {
                    console.log("ERROR GOOGLE", reason);
                    reject(reason);
                  });
                // ...
              })
              .catch((error) => {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...

                reject(error.code);
              });
          }
          break;
        case "facebook":
          if (this.platform.is("hybrid") && (this.platform.is("android") || this.platform.is("ios"))) {
            //if (this.deviceModeService.getDevice() != 'desktop') {

            // FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })
            //   .then((credentials: FacebookLoginResponse) => {
            //     const facebook_credential = firebase.auth.FacebookAuthProvider.credential(credentials.accessToken.token);

            //     this.afAuth
            //       .signInWithCredential(facebook_credential)
            //       .then((authuser) => {
            //         let fb = authuser.additionalUserInfo;

            //         if (fb.profile["birthday"]) {
            //           authuser.additionalUserInfo.profile["birthdate"] = moment(fb.profile["birthday"]).format("YYYY-MM-DD");
            //         }
            //         if (fb.profile["first_name"]) {
            //           authuser.additionalUserInfo.profile["given_name"] = fb.profile["first_name"];
            //         }
            //         if (fb.profile["last_name"]) {
            //           authuser.additionalUserInfo.profile["family_name"] = fb.profile["last_name"];
            //         }

            //         this.saveUser(provider, authuser)
            //           .then((user) => {
            //             resolve(authuser);
            //           })
            //           .catch((reason) => {
            //             console.log("ERROR FACEBOOK", reason);
            //             reject(reason);
            //           });
            //       })
            //       .catch((reason) => {
            //         reject(reason);
            //       });
            //   })
            //   .catch((reason) => {
            //     reject(reason);
            //   });
          } else {
            const provider = new firebase.auth.FacebookAuthProvider();
            provider.addScope("user_birthday");
            provider.addScope("user_gender");

            firebase
              .auth()
              .signInWithPopup(provider)
              .then((authuser: firebase.auth.UserCredential) => {
                let fb = authuser.additionalUserInfo;

                if (fb.profile["birthday"]) {
                  authuser.additionalUserInfo.profile["birthdate"] = moment(fb.profile["birthday"]).format("YYYY-MM-DD");
                }
                if (fb.profile["first_name"]) {
                  authuser.additionalUserInfo.profile["given_name"] = fb.profile["first_name"];
                }
                if (fb.profile["last_name"]) {
                  authuser.additionalUserInfo.profile["family_name"] = fb.profile["last_name"];
                }

                this.saveUser(provider, authuser)
                  .then((user) => {
                    resolve(authuser);
                  })
                  .catch((reason) => {
                    console.log("ERROR FACEBOOK", reason);
                    reject(reason);
                  });
              })
              .catch((error) => {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...

                reject(error.code);
              });
          }

          break;
        case "apple":
          SignInWithApple.authorize()
            .then(async (credentials) => {
              if (credentials.response && credentials.response.identityToken) {
                const apple_provider = new firebase.auth.OAuthProvider("apple.com");

                // Create sign in credentials with our token
                const apple_credential = apple_provider.credential({
                  idToken: credentials.response.identityToken,
                });

                this.afAuth
                  .signInWithCredential(apple_credential)
                  .then((authuser) => {
                    if (credentials.response.givenName && authuser.additionalUserInfo && authuser.additionalUserInfo.profile) {
                      authuser.additionalUserInfo.profile["given_name"] = credentials.response.givenName;
                    }
                    if (credentials.response.familyName && authuser.additionalUserInfo && authuser.additionalUserInfo.profile) {
                      authuser.additionalUserInfo.profile["family_name"] = credentials.response.familyName;
                    }

                    this.saveUser(provider, authuser)
                      .then((user) => {
                        resolve(authuser);
                      })
                      .catch((reason) => {
                        console.log("ERROR APPLE", reason);
                        reject(reason);
                      });
                  })
                  .catch((reason) => {
                    reject(reason);
                  });
              } else {
                reject("error_apple_login");
              }
            })
            .catch((reason) => {
              reject(reason);
            });
      }
    });
  }

  resetPassword(step, data) {
    data.action = step;
    return this.http.post(environment.api.auth.resetpassword, data).toPromise();
  }
}
