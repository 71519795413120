import { Injectable } from "@angular/core";
import { Geolocation, Position } from "@capacitor/geolocation";
import { NativeGeocoder, NativeGeocoderOptions, NativeGeocoderResult } from "@awesome-cordova-plugins/native-geocoder/ngx";
import { CommonService } from "./common.service";

export interface Coords {
  lat: number;
  lng: number;
}

@Injectable({
  providedIn: "root",
})
export class GeolocationService {
  public place = null;
  public location: Coords = { lat: null, lng: null };
  public city = null;
  public country_iso = null;
  private watch_id = null;
  public last_watch: Position = null;
  public coords: Coords = null;
  private is_init: boolean = false;

  constructor(private common: CommonService, private nativeGeocoder: NativeGeocoder) {}

  init() {
    if (!this.is_init && this.common.isInMobileApp()) {
      // use native, avoid to call google api
      this.loadNativeGeocoder();
      this.is_init = true;
    }
  }

  loadNativeGeocoder() {
    let options: NativeGeocoderOptions = {
      useLocale: true,
      maxResults: 5,
    };

    this.getCurrentPosition()
      .then((coords: Coords) => {
        this.location = coords;
        this.nativeGeocoder.reverseGeocode(this.location.lat, this.location.lng, options).then((result: NativeGeocoderResult[]) => {
          this.city = result[0].locality;
          this.country_iso = result[0].countryCode;
        });
      })
      .catch((error: any) => console.log(error));
  }

  /*
   * Returns current position
   */
  getCurrentPosition(request_permissions = false): Promise<Coords> {
    return new Promise((resolve, reject) => {
      Geolocation.getCurrentPosition()
        .then((coordinates) => {
          let coords: Coords;
          coords = {
            lat: coordinates.coords.latitude,
            lng: coordinates.coords.longitude,
          };
          resolve(coords);
        })
        .catch((err) => {
          console.log("Error GPS", err);
          if (request_permissions) {
            Geolocation.requestPermissions();
          }
          reject("gps_not_activated");
        });
    });
  }

  watch() {
    this.watch_id = Geolocation.watchPosition(
      {
        enableHighAccuracy: true,
        maximumAge: 25000,
        timeout: 7500,
      },
      (position: Position, err) => {
        if (!err) {
          this.last_watch = position;
        } else {
          console.log("Error GPS :", err);
          Geolocation.clearWatch(this.watch_id);
          setTimeout(() => {
            this.watch();
          }, 30000);
        }
      }
    );
  }
}
