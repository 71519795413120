import * as moment from 'moment';

export class Photo {
  public id: string = null;
  public url: string = null;
  public tmp: string = '';
  public date: string = moment()
    .utc()
    .format('YYYY-MM-DD HH:mm:ss');
  public date_approval: string = null;
  public uid_approval: string = null;
  public reason_approval: string = null;
  public status: string = '';
  public private: boolean = false;
  public filename: string = null;
  public tmp_filename: string = null;
  public uid: string = null;
  public thumbnail_url: string = null;
  public thumbnail: string = null;

  constructor(id?: string | Object) {
    if (id) {
      if (typeof id == 'object') {
        this.import(id);
      } else {
        this.id = id;
      }
    }
  }

  exists() {
    return this.id !== null;
  }

  import(data) {
    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        this[key] = data[key];
      }
    }
  }
}
