<ion-header>
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-button (click)="closeModal()"> <ion-icon slot="icon-only" name="close"></ion-icon> </ion-button>
    </ion-buttons>
    <ion-title>{{ imgTitle }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [forceOverscroll]="false" color="dark" fullscreen="true">
  <swiper-container [initialSlide]="imgIdx" #slider pagination="true">
    <swiper-slide
      *ngFor="let img of gallery">
      <div class="swiper-zoom-container"><img [src]="img?.url" height="100vh" /></div>
    </swiper-slide>
  </swiper-container>
</ion-content>
